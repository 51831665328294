import React, { useState, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import Popup from '../../Components/Popup/Popup'
import AccessLevelDropDown from '../../Pages/Employees/Support/AccessLevelDropDown/AccessLevelDropDown'
import LocationsSelection from '../../Pages/Employees/Support/LocationsSelection/LocationsSelection'
import { addLocation, inviteAdmin } from '../../Redux/Slices/employeeSlice'
import './EmployeeAdd.css'

interface EmployeeAddProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
}

export default function EmployeeAdd({ visible, setVisible }: EmployeeAddProps) {
	const dispatch = useDispatch()

	const [employee, setEmployee] = useState({ role: 'admin', adminEmail: '', availableLocations: [] })
	const [error, setError] = useState(null)

	const onLocationChange = (locationID: number, newState: Boolean, employee: any) => {
		if (newState) {
			setEmployee({
				...employee,
				availableLocations: [
					...employee.availableLocations,
					{ locationID, adminLocationID: true }
				]
			})
		} else {
			setEmployee({
				...employee,
				availableLocations: employee.availableLocations.filter((location: any) => location.locationID != locationID)
			})
		}
	}


	const onSubmit = () => {
		dispatch(inviteAdmin({
			employee,
			callBack: (status: string, data: any) => {
				if (status == 'error') {
					setError(data)
				} else {
					if (employee.availableLocations?.length) {
						dispatch(addLocation({
							locations: employee.availableLocations.map(({ locationID }: {locationID: number}) => locationID),
							email: employee.adminEmail
						}))
					}
					setVisible(false)
				}
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='+Invite Employee'
			className='EmployeeAdd thinContainer'
		>
			<div className="story error">
				{error}
			</div>
			
			<div className="story body">
				<input
					type="text"
					className="input wide emailInput"
					placeholder="Email"
					onChange={(e) => {
						setEmployee({ ...employee, adminEmail: e.target.value })
					}}
					name='email'
				/>
				<AccessLevelDropDown
					employee={employee}
					onAccessLevelChange={(role: string) => {
						setEmployee({ ...employee, role })
					}}
				/>
				<div className="LocationSelectionWrapper">
					<LocationsSelection
						employee={employee}
						onLocationChange={onLocationChange}
					/>
				</div>
				<div className="action">
					<button className="button neutral" onClick={onSubmit}>
						+Invite Employee
					</button>
				</div>
			</div>
			
		</Popup>
	)
}
