import React, { MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import './LocationCard.css'

interface LocationCardProps {
    location: any;
	onSelect: MouseEventHandler<any>;
}

export default function LocationCard({
	location,
	onSelect
}: LocationCardProps) {
	return (
		<Link to='/Orders'>
			<div className='LocationCard' onClick={onSelect}>
				{location.locationID} - {location.title}
			</div>
		</Link >
	)
}
