import { createSlice } from '@reduxjs/toolkit'

const viewSlice = createSlice({
	name: 'view',
	initialState: {
		screenSize: '',
		hasPopup: false
	},
	
	reducers: {
		setScreenSize: (state, { payload }) => {
			state.screenSize = payload
		},
		openPopup: (state) => {
			state.hasPopup = true
		},
		closePopup: (state) => {
			state.hasPopup=false
		}
	}
	
})

export const { setScreenSize, openPopup, closePopup } = viewSlice.actions
export default viewSlice.reducer