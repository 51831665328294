import React, { useEffect, useState, SetStateAction } from 'react'
import Popup from '../../Components/Popup/Popup'
import './ConfirmStatusChangePopup.css'
import { FreeButton } from 'react-ui-scaffold'
import { overrideOrderStatus, statusBadgeColorMap } from '../../Redux/Slices/orderSlice'
import { useDispatch } from 'react-redux'
import CommentView from './Support/CommentView'

interface ConfirmActionPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
	orderID: string
	status: string
}

export default function ConfirmStatusChangePopup({ visible, setVisible, orderID, status }: ConfirmActionPopupProps) {
	const dispatch = useDispatch()

	const [view, setView] = useState('status confirm')
	const onStatusUpdate = () => {
		dispatch(overrideOrderStatus({
			orderID,
			status,
			callback: () => {
				setView('comments')
			}
		}))
	}

	useEffect(() => {
		setView('status confirm')
	}, [visible])

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={view == 'status confirm' ? 'Manually Override Status' : 'Status Changed'}
			className='ConfirmStatusPopup'
		>
			<div className="story body">
				{view == 'status confirm' ? (
					<>
						<div>
							Are you sure you want to change the status of this order to&nbsp;
							<span style={{ color: statusBadgeColorMap[status] }}>{status}</span>
							?
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={() => setVisible(false)}>Cancel</FreeButton>
							<FreeButton onClick={onStatusUpdate}>Yes, Override Status</FreeButton>
						</div>
					</>
				) : (
					<>
						<div>
							The status of this order has been changed to&nbsp;
							<span style={{ color: statusBadgeColorMap[status] }}>{status}</span>
						</div>
						<CommentView
							close={() => setVisible(false)}
							orderID={orderID}
						/>
					</>
				)}
			</div>

		</Popup>
	)
}
