import React, { useEffect, useState, SetStateAction } from 'react'
import Popup from '../../Components/Popup/Popup'
import './ConfirmActionPopup.css'
import { FreeButton } from 'react-ui-scaffold'
import CommentView from '../ConfirmStatusChangePopup/Support/CommentView'
import { useDispatch } from 'react-redux'
import { statusBadgeColorMap, updateStatus } from '../../Redux/Slices/orderSlice'
import { Actions, actionTextMap } from '../../Pages/Orders/Support/OrderDetailsSupport/ActionButton/ActionButton'

interface ConfirmActionPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
	action: Actions
	orderID: string
}

export default function ConfirmActionPopup({ visible, setVisible, action, orderID }: ConfirmActionPopupProps) {
	const dispatch = useDispatch()

	const [view, setView] = useState('action confirm')
	const [status, setStatus] = useState('')
	
	const onUpdateStatus = () => {
		dispatch(updateStatus({
			orderID,
			action,
			callback: ({ status }: {status: string}) => {
				console.log(status)
				setStatus(status)
				setView('comments')
			}
		}))
	}
	
	useEffect(() => {
		setView('action confirm')
		setStatus('')
	}, [visible])
	
	
	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={view == 'action confirm' ? 'Proceed to Next Step?' : 'Step Complete'}
			className='ConfirmActionPopup'
		>
			
			<div className="story body">
				{view == 'action confirm' ? (
					<>
						<div>
							Would you like to proceed to the step:&nbsp;<span style={{ color: actionTextMap[action]?.color }}>{actionTextMap[action]?.text}</span>?
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={() => setVisible(false)}>Cancel</FreeButton>
							<FreeButton onClick={onUpdateStatus}>Confirm</FreeButton>
						</div>
					</>
				) : (
					<>
						<div>
							The status of this order has been changed to&nbsp;
							<span style={{ color: statusBadgeColorMap[status] }}>{status}</span>
						</div>
						<CommentView
							close={() => setVisible(false)}
							orderID={orderID}
						/>
					</>
				)}
			</div>
			
		</Popup>
	)
}
