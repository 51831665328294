import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { markItemAsDone, markItemAsNotDone, Order } from '../../../../../../Redux/Slices/orderSlice'
import CollapsibleCard from '../../../../../../Components/CollapsibleCard/CollapsibleCard'
import LabelText from '../../../../../../Components/LabelText/LabelText'

export default function ItemCard({ item, order, index }: { item: any, order: Order, index: number }) {
	const dispatch = useDispatch()
	const formmatedProductName = useMemo(() => {
		if (item.type == 'Purchase') {
			return item.productName
		}
		return ''
	}, [item])
	const formattedSkuCode = useMemo(() => {
		if (item.type == 'Purchase') {
			return `${item.skuCode}-${item.productSkuName}`
		}
		return ''
	}, [item])
	const itemTotal = useMemo(() => {
		return (item.quantity * item.price / 100).toFixed(2)
	}, [item])

	const onOpen = () => {
		dispatch(markItemAsNotDone({ itemID: item.itemID }))
	}

	const onClose = () => {
		dispatch(markItemAsDone({ itemID: item.itemID }))
	}

	return (

		<CollapsibleCard
			defaultExpanded={!item.done}
			onOpen={onOpen}
			onClose={onClose}
			expandedContent={
				<div>
					{ item.advancedNotes ? (
						<div className="AdvancedNotes">
							&quot;{item.advancedNotes}&quot;
						</div>
					) : (
						<>
							<LabelText
								label='Hollow'
								text={item.hollowValue}
							/>
							<LabelText
								label='Radius'
								text={item.radiusValue}
							/>
							<LabelText
								label='Pitch'
								text={item.pitchValue}
							/>
						</>
					)}
					<LabelText
						label='Price'
						text={itemTotal}
					/>
					<LabelText
						label='Order Code'
						text={`${order.shortID} - ${index + 1}`}
					/>
				</div>
			}
		>
			<div>{formmatedProductName}</div>
			<div>{formattedSkuCode}</div>
			<div>{`${item.specType} (x ${item.quantity})`}</div>
			
		</CollapsibleCard>
	)
}
