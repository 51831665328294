import React, { useCallback, useState } from 'react'
import LoggedInOnly from '../../Components/LoggedInOnly/LoggedInOnly'
import { FreeButton, Page } from 'react-ui-scaffold'
import './Order.css'
import MainContainer from '../../Components/MainContainer/MainContainer'
import InternalContainer from '../../Components/InternalContaier/InternalContainer'
import StatusTab from './Support/StatusTab/StatusTab'
import SideBar from './Support/SideBar/SideBar'
import { faSearch, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrderDetails from './Support/OrderDetails/OrderDetails'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { clearActiveOrder, fetchFiltered, fetchOrder, fetchOrderCounts, fetchOrders, setOrderSearch } from '../../Redux/Slices/orderSlice'
import FilterPopup from '../../Popups/FilterPopup/FilterPopup'
import AppliedFilters from './Support/AppliedFilters/AppliedFilters'
import { useOrderLiveUpdates } from '../../Hooks/useOrderLiveUpdates'
import _ from 'lodash'
import { setHasNewOrder } from '../../Redux/Slices/activeUserSlice'

export default function Orders({ match }:any) {
	const history = useHistory()
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [type, setType] = useState('Active')
	const [initialized, setInitialized] = useState(false)
	const [advancedFilters, setAdvancedFilters] = useState({})
	const [filterPopupOpen, setFilterPopupOpen] = useState(false)
	const [localSearch, setLocalSearch] = useState('')
	const { activeLocation, hasNewOrder } = useSelector((s: any) => s.activeUser)
	const { locationID } = activeLocation || {}
	const search = useSelector((s: any) => s.order.search)
	if (!activeLocation) {
		history.push('/')
	}

	useEffect(() => {
		if (match.params.id) {
			dispatch(fetchOrder({ orderID: match.params.id }))
		}
	}, [])
	
	useEffect(() => {
		setPage(1)
		if (search) {
			setAdvancedFilters({})
		}
	}, [activeLocation, search])

	useEffect(() => {
		if (initialized) {
			dispatch(clearActiveOrder())
			history.push('/Orders')
		} else {
			setInitialized(true)
		}
	}, [type])

	const delayedQuery = useCallback(_.debounce((search: any) => {
		dispatch(setOrderSearch({ search }))
	}, 300), [])

	const setSearch = (search: string) => {
		setLocalSearch(search)
		delayedQuery(search)
	}

	const onSelectType = (type: string) => {
		setPage(1)
		setType(type)
	}

	const filter = (filters: any) => {
		setAdvancedFilters(filters)
		setFilterPopupOpen(false)
		setPage(1)
	}

	const advancedFilterSearch = () => {
		const {
			statuses = [],
			dateType,
			beforeDate,
			afterDate,
			fromDate,
			toDate,
			firstName,
			lastName,
			email,
			phone
		} = advancedFilters as any
		let filters: any = {
			statuses,
			firstName,
			lastName,
			email,
			phone
		}
		if (dateType == 'Before') {
			filters = { ...filters, beforeDate }
		} else if (dateType == 'After') {
			filters = { ...filters, afterDate }
		} else if (dateType == 'Range') {
			filters = { ...filters, fromDate, toDate }
		}
		dispatch(fetchFiltered({ filters, location: locationID, page: 1 }))
	}

	useEffect(() => {
		if (Object.keys(advancedFilters).length) {
			setSearch('')
			advancedFilterSearch()
		}
	}, [advancedFilters])
	
	useEffect(() => {
		_fetchOrders()
		_fetchOrderCounts('Active')
		_fetchOrderCounts('Archived')
	}, [search, locationID])
	
	useEffect(() => {
		_fetchOrders()
	}, [page, type])

	useEffect(() => {
		_fetchOrderCounts('Active')
		_fetchOrderCounts('Archived')
	}, [type])

	const _fetchOrders = (newPage?: number) => {
		dispatch(fetchOrders({ location: locationID, type, search, page: newPage || page }))
	}

	const _fetchOrderCounts = (type: string) => {
		dispatch(fetchOrderCounts({ location: locationID, type, search }))
	}

	const onRefresh = () => {
		dispatch(setHasNewOrder(false))
		if (Object.keys(advancedFilters).length) {
			return advancedFilterSearch()
		}
		
		setPage(1)
		_fetchOrderCounts('Active')
		_fetchOrderCounts('Archived')
		_fetchOrders(1)
	}

	useOrderLiveUpdates()

	const hasAdvancedFilters = Boolean(Object.keys(advancedFilters).length)

	return (
		<Page className='Orders'>
			<LoggedInOnly>
				<FilterPopup
					advancedFilters={advancedFilters}
					visible={filterPopupOpen}
					setVisible={setFilterPopupOpen}
					onSubmit={filter}
				/>
				<MainContainer>
					<InternalContainer className="SideBarSearchContainer">
						<div className="SideBarContainer">
							{hasAdvancedFilters ?
								<AppliedFilters
									advancedFilters={advancedFilters}
									setAdvancedFilters={setAdvancedFilters}
									onChangeFilter={() => {
										setFilterPopupOpen(true)
									}}
								/> :
								<div className="StatusTabsContainer">
									<StatusTab
										type="Active"
										current={type}
										onSelect={onSelectType}
									/>
									<StatusTab
										type="Archived"
										current={type}
										onSelect={onSelectType}
									/>
								</div>
							}
							<SideBar
								type={type}
								setPage={setPage}
								page={page}
								hasAdvancedFilters={hasAdvancedFilters}
							/>
						</div>
						<div className="SearchContainer">
							<div className="SearchBox">
								<FontAwesomeIcon icon={faSearch} color="#b7b7b7" className="SearchIcon" />
								<input
									type="text"
									className="input SearchInput"
									value={localSearch}
									onChange={(e: any) => setSearch(e.target.value)}
									placeholder="Search an order code"
								/>
							</div>
							<button
								className="button neutral plain FilteredSearch"
								onClick={() => setFilterPopupOpen(true)}
							>
								Filtered Search
							</button>
						</div>
					</InternalContainer>
						
					<OrderDetails />
					{hasNewOrder ? (
						<InternalContainer className="NewOrdersButtonContainer">
							<FreeButton
								className="NewOrdersButton"
								onClick={() => onRefresh()}
							>
								<div>New Orders</div>
								<FontAwesomeIcon
									icon={faSync}
									color="#FFF"
								/>
							</FreeButton>
						</InternalContainer>
					) : null}
				</MainContainer>
			</LoggedInOnly>
		</Page>
	)
}
