import { useDispatch, useSelector } from 'react-redux'
import Pusher from 'pusher-js'
import { fetchOrder, Order, updateOrderStatus } from '../Redux/Slices/orderSlice'
import { setHasNewOrder } from '../Redux/Slices/activeUserSlice'

var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
	cluster: 'us2'
})

const channel = pusher.subscribe('orders')

export function useOrderLiveUpdates() {
	const dispatch = useDispatch()
	const { activeLocation, hasNewOrder } = useSelector((s: any) => s.activeUser)
	const order: null | Order = useSelector((s: any) => s.order.activeOrder)


	channel.unbind('new-order')
	channel.bind('new-order', ({ dropOffLocation }: any) => {
		if (activeLocation?.locationID == dropOffLocation && !hasNewOrder) {
			dispatch(setHasNewOrder(true))
		}
	})

	channel.unbind('status-update')
	channel.bind('status-update', ({ dropOffLocation, orderID, newStatus }: any) => {
		if (activeLocation?.locationID == dropOffLocation) {
			dispatch(updateOrderStatus({ orderID, newStatus }))
			if (orderID == order?.orderID) {
				dispatch(fetchOrder({ orderID }))
			}
		}
	})

	channel.unbind('new-comment')
	channel.bind('new-comment', ({ orderID }: any) => {
		if (orderID == order?.orderID) {
			dispatch(fetchOrder({ orderID }))
		}
	})

	channel.unbind('order-item-done-updated')
	channel.bind('order-item-done-updated', ({ orderID }: any) => {
		if (orderID == order?.orderID) {
			dispatch(fetchOrder({ orderID }))
		}
	})

	channel.unbind('order-refunded')
	channel.bind('order-refunded', ({ orderID }: any) => {
		if (orderID == order?.orderID) {
			dispatch(fetchOrder({ orderID }))
		}
	})


	return null
}
