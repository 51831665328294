import React from 'react'

import './App.css'
import './Assets/fonts/ef_conv_zip_qlwBN52tL5xq1sc9ZoTtoweF/hemi_head_bd_it.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { useScreenSize } from './Hooks/useScreenSize'
import { useSkatescribeAuthState } from './Hooks/useSkatescribeAuthState'
import { useSelector } from 'react-redux'
import { Page } from 'react-ui-scaffold'
import Main from './Pages/Main/Main'

export default function App() {
	
	useScreenSize()
	
	const { screenSize, hasPopup } = useSelector((s: any) => s.view)
	const authStatus = useSelector((state: any) => state.activeUser.status)
	
	useSkatescribeAuthState()
	
	const contentClass = hasPopup ? ' appBackground ' : ''
	
	if (authStatus === 'Loading'){
		return <Page loading={true}/>
	}
	
	return (
		<BrowserRouter>
			<div className={'App ' + screenSize}>

				<div id='portalDestination-popupContainer' className='portalDestination'></div>

				{
					process.env.REACT_APP_ENV &&
          process.env.REACT_APP_ENV !== 'production' &&
          <div className='env hemi'>
            This is the {process.env.REACT_APP_ENV} site
          </div>
				}
				
				<div className={'appContents ' + contentClass}>
					<Switch>
						<Route path='/' component={Main} />
					</Switch>
				</div>
				
				
			</div>
		</BrowserRouter>
	)
}
