import React, { useState } from 'react'
import './OrderDetails.css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchValidActionsForStatus, statusBadgeColorMap } from '../../../../Redux/Slices/orderSlice'
import StatusDropdown from '../OrderDetailsSupport/StatusDropdown/StatusDropdown'
import ActionButton, { Actions } from '../OrderDetailsSupport/ActionButton/ActionButton'
import ConfirmActionPopup from '../../../../Popups/ConfirmActionPopup/ConfirmActionPopup'
import CustomerInfo from '../OrderDetailsSupport/CustomerInfo/CustomerInfo'
import PaymentInfo from '../OrderDetailsSupport/PaymentInfo/PaymentInfo'
import OrderHistory from '../OrderDetailsSupport/OrderHistory/OrderHistory'
import Comments from '../OrderDetailsSupport/Comments/Comments'
import OrderContent from '../OrderDetailsSupport/OrderContent/OrderContent'
import ConfirmRefundPopup from '../../../../Popups/ConfirmRefundPopup/ConfirmRefundPopup'

export default function OrderDetails() {
	const dispatch = useDispatch()
	const order = useSelector((s: any) => s.order.activeOrder)
	const validActions: Actions[] = useSelector((s: any) => s.order.validActions)
	const [confirmActionPopupOpen, setConfirmActionPopupOpen] = useState(false)
	const [confirmRefundPopupOpen, setConfirmRefundPopupOpen] = useState(false)
	const [activeAction, setActiveAction] = useState<Actions>()
	useEffect(() => {
		if (order?.status) {
			dispatch(fetchValidActionsForStatus({ status: order.status }))
		}
	}, [order?.status])
	if (!order) {
		return null
	}
	
	return (
		<div
			className="OrderDetails"
		>
			{activeAction ? (
				<ConfirmActionPopup
					visible={confirmActionPopupOpen}
					setVisible={setConfirmActionPopupOpen}
					action={activeAction}
					orderID={order.orderID}
				/>
			) : null}
			<div className="Header">

			</div>
			<div className="BodyContainer">
				<div className="Body" style={{ paddingRight: 30 }}>
					<div className="Title">Order #{order.shortID} ({order.itemCount} items)</div>
					<div className="CurrentStatus">
						Status:&nbsp;
						<span style={{ color: statusBadgeColorMap[order.status] }}>{order.status}</span>
					</div>
					<div>
						<OrderContent order={order} />
					</div>
					<StatusDropdown order={order} />
				</div>
				<div className="Body">
					<div className="NextStep">
						{validActions.length ? (
							<>
								<div className="NextStepTitle">Next Step:</div>
								{validActions.map((action: Actions) => {
									return <ActionButton key={action} action={action} setConfirmActionPopupOpen={() => {
										setConfirmActionPopupOpen(true)
										setActiveAction(action)
									}} />
								})}
							</>
						) : null
						}
					</div>
					<CustomerInfo order={order} />
					<PaymentInfo order={order} />
					<OrderHistory order={order} />
					<Comments order={order} />
					{!order.refunded ? (
						<div
							className="RefundOrderButton"
							onClick={() => setConfirmRefundPopupOpen(true)}
						>
							Refund Order
						</div>
					) : null}
					<ConfirmRefundPopup
						visible={confirmRefundPopupOpen}
						setVisible={setConfirmRefundPopupOpen}
						order={order}
					/>
				</div>
			</div>
		</div>
	)
}
