import React from 'react'
import './SideBar.css'
import { useSelector } from 'react-redux'
import OrderCard from '../SideBarSupport/OrderCard/OrderCard'
import { pageSize } from '../../../../Redux/Slices/orderSlice'

interface SideBarProps {
	type: string;
	page: number;
	setPage: Function;
	hasAdvancedFilters: Boolean;
}

export default function SideBar({
	type,
	page,
	setPage,
	hasAdvancedFilters
}: SideBarProps) {
	const orders = useSelector((s: any) => s.order.orders[type] || [])
	const filteredOrders = useSelector((s: any) => s.order.orders.Filtered || [])
	const orderCount = useSelector((s: any) => s.order.counts[type] || 0)
	return (
		<div
			className="SideBar"
		>
			{(hasAdvancedFilters ? filteredOrders : orders).map((order: any) => {
				return (
					<OrderCard
						key={order.orderID}
						order={order}
					/>
				)
			})}
			{pageSize * page < orderCount ? (
				<div className="LoadMore" onClick={() => setPage(page + 1)}>Load more</div>
			) : null}
		</div>
	)
}
