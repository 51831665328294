import React from 'react'
import { useSelector } from 'react-redux'

interface LoggedOutOnlyProps {
  children: React.ReactNode;
}

export default function LoggedOutOnly({ children }: LoggedOutOnlyProps) {

	const { status } = useSelector((s: any) => s.activeUser)

	if (status === 'Logged In') {
		return null
	}

	return <>{children}</>
}
