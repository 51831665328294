import React from 'react'
import { Order } from '../../../../../Redux/Slices/orderSlice'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import LabelText from '../../../../../Components/LabelText/LabelText'
import './PaymentInfo.css'
import CreditCardInfo from '../../../../../Components/CreditCardInfo/CreditCardInfo.'

export default function PaymentInfo({ order }: { order: Order }) {
	const { cardDetails = {} } = order
	return (
		<div className='PaymentInfo'>
			<CollapsibleCard
				expandedContent={
					<>
						<CreditCardInfo
							cardDetails={cardDetails}
						/>
						<LabelText
							label="Postal Code"
							text={order.postalCode}
						/>
					</>
				}
			>
				Payment Info
			</CollapsibleCard>
		</div>
	)
}
