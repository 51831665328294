import React from 'react'
import LoggedInOnly from '../../Components/LoggedInOnly/LoggedInOnly'
import { Page } from 'react-ui-scaffold'
import './SelectLocation.css'
import MainContainer from '../../Components/MainContainer/MainContainer'
import { useDispatch, useSelector } from 'react-redux'
import LocationCard from './LocationCard/LocationCard'
import { saveLocation } from '../../Redux/Slices/activeUserSlice'

export default function SelectLocation() {
	const dispatch = useDispatch()
	const locations = useSelector((s: any) => s.activeUser.data?.availableLocations || [])

	return (
		<Page className='SelectLocation'>
			<LoggedInOnly>
				
				<MainContainer>
					<div className="SelectLocation">
						<div className="titleBar">
							<div className="title hemi">Select a Location</div>
						</div>
						<div className="CardsContainer">
							{locations.map((location: any) => {
								return (
									<LocationCard
										key={location.locationID}
										location={location}
										onSelect={() => dispatch(saveLocation(location))}
									/>
								)
							})}
						</div>
					</div>
				</MainContainer>
				
			</LoggedInOnly>
		</Page>
	)
}
