import React from 'react'
import { useSelector } from 'react-redux'

interface LoggedInOnlyProps {
  children: React.ReactNode;
}

export default function LoggedInOnly({ children }: LoggedInOnlyProps) {

	const { status } = useSelector((s: any) => s.activeUser)

	if (status !== 'Logged In') {
		return null
	}

	return <>{children}</>
}
