import firebase from 'firebase/app'
import 'firebase/auth'
import beam from '../Handlers/beam'


//changes error strings/codes into a more readable form
const errorMap = {
	'auth/invalid-email': 'Invalid email!',
	'auth/user-not-found': 'No account found with this email!'
}

//Create a new account

interface CreateAccountParams {
	email: string;
	password: string;
	name: string;
}

async function createAccount({ email, password, name }: CreateAccountParams) {
	try {

		const result = await beam.post('/api/admin/createAdmin', {
			name,
			email,
			password
		})

		return { success: true, body: result.data.message }

	} catch (err) {

		console.error(err)

		const errorCode = errorMap[err.code as keyof typeof errorMap]

		if (errorCode) {
			return { error: errorCode }
		}

		return { error: err.response.error }
	}
}

//Regular log in
async function login(email: string, password: string) {
	return await firebase.auth().signInWithEmailAndPassword(email, password).catch(err => {
		const errorCode = errorMap[err.code as keyof typeof errorMap]
		if (errorCode) {
			return Promise.reject(errorCode)
		}

		console.log(err)
		return Promise.reject('Something went wrong')
	})
}

//Reset
async function resetPassword(email: string) {
	try {
		await firebase.auth().sendPasswordResetEmail(email)
		return {}
	} catch (err) {
		console.error(err)

		const errorCode = errorMap[err.code as keyof typeof errorMap]

		if (errorCode) {
			return { error: errorCode }
		}

		return { error: err.message }
	}
}

function logout() {
	localStorage.clear()
	return firebase.auth().signOut()
}

async function getToken() {
	try {
		return await firebase.auth().currentUser?.getIdToken()
	} catch (err) {
		return ''
	}

}

export default {
	createAccount,
	login,
	logout,
	resetPassword,
	getToken
}
