import React from 'react'

export const actionTextMap = {
	ORDER_APPROVED: {
		text: 'Approve Order',
		color: '#29B933'
	},
	ORDER_REJECTED: {
		text: 'Reject Order/Contact Customer',
		color: '#D00000'
	},
	CUSTOMER_CONTACTED: {
		text: 'Customer Contacted/Waiting for Blades',
		color: '#EE6E00'
	},
	RUNNERS_DROPPED_OFF: {
		text: 'Blades Received/Order in Progress',
		color: '#29B933'
	},
	SHARPENED_AND_READY: {
		text: 'Ready for Pickup',
		color: '#EE6E00'
	},
	ORDER_PICKED_UP: {
		text: 'Blades Picked Up/Order Completed',
		color: '#29B933'
	}
}

export type Actions = 'ORDER_APPROVED' | 'ORDER_REJECTED' | 'CUSTOMER_CONTACTED' | 'RUNNERS_DROPPED_OFF' | 'SHARPENED_AND_READY' | 'ORDER_PICKED_UP';

export default function ActionButton({ action, setConfirmActionPopupOpen }: { action: Actions, setConfirmActionPopupOpen: Function }) {
	const { text, color } = actionTextMap[action] || {}
	return (
		<>
			<div
				onClick={() => { setConfirmActionPopupOpen(true) }}
				className="Status"
				style={{ backgroundColor: color }}
			>
				{text}
			</div>
		</>
	)
}
