import React from 'react'
import { Admin } from '../../../../Redux/Slices/employeeSlice'
import './AccessLevelDropDown.css'

export default function AccessLevelDropDown({ employee, onAccessLevelChange }: { employee: Admin, onAccessLevelChange: Function }) {
	return (
		<div className="AccessLevelDropDown">
			<div>Access Level:</div>
			<select
				className='input'
				onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
					onAccessLevelChange(e.target.value, employee)
				}}
				defaultValue={employee.role}
			>
				<option value="" disabled hidden>Choose Access Level</option>
				<option
					value="admin"
				>
					Admin
				</option>
				<option
					value="owner"
				>
					Owner
				</option>
			</select>
		</div>
	)
}
