import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'

export const pageSize = 50

export const fetchOrderCounts = createAsyncThunk('orders/counts', async ({ location, type = '', search }: { location: string, type?: string, search?: string }) => {
	const fetchRes = await beam.get(`/api/admin/fetchOrderCountByLocation/${location}?type=${type}${search ? `&search=${search}` : ''}`).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
		type
	}
})

export const fetchOrders = createAsyncThunk('orders/fetch', async ({ location, type = '', search, page }: { location: string, type?: string, search?: string, page: number }) => {
	const fetchRes = await beam.get(`/api/admin/fetchOrdersByLocation/${location}?type=${type}&page=${page}${search ? `&search=${search}`: ''}`).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
		type
	}
})

export const fetchOrder = createAsyncThunk('order/fetch', async ({ orderID }: { orderID: string }) => {
	const fetchRes = await beam.post('/api/admin/fetchOrder', { orderID }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
	}
})

export const fetchFiltered = createAsyncThunk('order/fetchFiltered', async ({ location, filters, search, page }: { location: string, filters: any, search?: string, page: number }) => {
	const fetchRes = await beam.post(`/api/admin/fetchFilteredOrdersByLocation/${location}?page=${page}${search ? `&search=${search}` : ''}`, { filters }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
		type: 'Filtered'
	}
})

export const markItemAsDone = createAsyncThunk('order/markItemAsDone', async ({ itemID }: { itemID: number }) => {
	const updateRes = await beam.post('/api/admin/markItemAsDone', { itemID }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: updateRes.response.data,
	}
})

export const markItemAsNotDone = createAsyncThunk('order/markItemAsNotDone', async ({ itemID }: { itemID: number }) => {
	const updateRes = await beam.post('/api/admin/markItemAsNotDone', { itemID }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: updateRes.response.data,
	}
})

export const updateStatus = createAsyncThunk('order/updateStatus', async ({ orderID, action, callback }: { orderID: string, action: string, callback: Function }) => {
	const updateRes = await beam.post(`/api/admin/updateStatus/${action}`, { orderID }).catch(err => {
		return Promise.reject(err)
	})
	
	callback(updateRes.response.data?.updatedOrder)

	return {
		data: updateRes.response.data,
	}
})

export const fetchValidActionsForStatus = createAsyncThunk('order/fetchValidActionsForStatus', async ({ status }: { status: string }) => {
	const fetchValidActionsRes = await beam.post('/api/admin/fetchValidActionsForStatus', { status }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchValidActionsRes.response.validActions,
	}
})

export const overrideOrderStatus = createAsyncThunk('order/overrideOrderStatus', async ({ orderID, status, callback }: { orderID: string, status: string, callback: Function }) => {
	const overrideOrderStatusRes = await beam.post('/api/admin/overrideOrderStatus', { orderID, status }).then().catch(err => {
		return Promise.reject(err)
	})

	callback()

	return {
		data: overrideOrderStatusRes.response.data,
	}
})

export const fetchValidStatuses = createAsyncThunk('order/fetchValidStatuses', async () => {
	const fetchValidStatusesRes = await beam.get('/api/admin/fetchValidStatuses').catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchValidStatusesRes.response.validStatuses,
	}
})

export const commentOnOrder = createAsyncThunk('order/orderComment', async ({ orderID, comment }: { orderID: string, comment: string }, thunk: any) => {
	const orderCommentRes = await beam.post('/api/admin/comment', { orderID, comment }).catch(err => {
		return Promise.reject(err)
	})
	return {
		data: { ...orderCommentRes.response.data, ...thunk.getState().activeUser.data },
	}
})

export const refundOrder = createAsyncThunk('order/refundOrder', async ({ orderID, callback }: { orderID: string, callback: Function }) => {
	const orderRefundRes = await beam.post('/api/admin/refundOrder', { orderID }).catch(err => {
		callback(err.response.error)
		return Promise.reject(err)
	})

	callback()

	return {
		data: { ...orderRefundRes.response.data },
	}
})
	

export interface Order {
	itemCount: number;
	placedBy: string;
	placedDate: Date;
	total: number;
	tax: number;
	dropOffLocation: number;
	contactFirstName: string;
	contactLastName: string;
	contactPhone: string;
	status: string;
	statusDate: Date;
	finalTotal: number;
	orderID: string;
	shortID: string;
	cardDetails?: any;
	logs?: any;
	postalCode: string;
	comments?: any;
	contents?: any
}

export const statusBadgeColorMap: { [key: string]: string } = {
	'Pending Review': 'var(--ssBlue)',
	'Contact Customer': 'var(--ssRed)',
	'Waiting for Blades': 'var(--ssOrange)',
	'Order Cancelled': 'var(--ssGrey)',
	'In Progress': 'var(--ssBlue)',
	'Ready For Pickup': 'var(--ssOrange)',
	'Order Complete': 'var(--ssGreen)'
}

const updateCartItem = (state: any, { payload }: any) => {
	const { data }: { data: any } = payload
	const { itemID }: { orderID: string, itemID: number } = data
	const itemIndex = state.activeOrder.contents.findIndex((cartItem: any) => {
		return cartItem.itemID == itemID
	})
	state.activeOrder.contents[itemIndex] = data
}

const ordersSlice = createSlice({
	name: 'orders',
	initialState: {
		orders: {
			Active: [],
			Archived: [],
			Filtered: []
		},
		counts: {
			Active: 0,
			Archived: 0,
		},
		loading: true,
		loaders: {
			Active: false,
			Archived: false,
		},
		activeOrder: null,
		validActions: [],
		validStatuses: [],
		search: ''
	},
	reducers: {
		clearActiveOrder: (state: any) => {
			state.activeOrder = null
		},
		updateOrderStatus: (state: any, { payload }: any) => {
			const { orderID, newStatus } = payload
			Object.keys(state.orders).map((type) => {
				state.orders[type] = state.orders[type].map((order: Order) => {
					if (order.orderID == orderID) {
						return { ...order, status: newStatus }
					}
					return order
				})
			})
		},
		setOrderSearch: (state: any, { payload }: any) => {
			const { search } = payload
			state.search = search
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchOrderCounts.fulfilled, (state: any, { payload }: any) => {
			const { data, type }: { data: Order[], type: string } = payload
			state.loading = false
			state.counts[type] = data
		})
		builder.addCase(fetchOrders.pending, (state: any, { meta: { arg } }: any) => {
			const { type }: { type: string } = arg
			state.loaders[type] = true
		})
		builder.addCase(fetchOrders.fulfilled, (state: any, { payload }: any) => {
			const { data, type }: { data: number, type: string } = payload
			state.loaders[type] = false
			state.orders[type] = data
		})
		builder.addCase(fetchFiltered.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			state.orders.Filtered = data
		})
		builder.addCase(fetchOrder.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			state.activeOrder = data
		})
		builder.addCase(markItemAsDone.fulfilled, updateCartItem)
		builder.addCase(markItemAsNotDone.fulfilled, updateCartItem)
		builder.addCase(fetchValidActionsForStatus.pending, (state: any) => {
			state.validActions = []
		})
		builder.addCase(fetchValidActionsForStatus.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			state.validActions = data
		})
		builder.addCase(fetchValidStatuses.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			state.validStatuses = data
		})
		builder.addCase(updateStatus.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			const { updatedOrder: { status }, statusLog }: any = data
			delete data.contents
			state.activeOrder = { ...state.activeOrder, status, logs: [statusLog, ...state.activeOrder.logs] }
			const acitveIndex = state.orders.Active.findIndex((order: Order) => state.activeOrder.orderID == order.orderID)
			if (acitveIndex != -1) {
				const newActive = [...state.orders.Active]
				newActive[acitveIndex] = { ...newActive[acitveIndex], status }
				state.orders.Active = newActive
			}
			const archivedIndex = state.orders.Archived.findIndex((order: Order) => state.activeOrder.orderID == order.orderID)
			if (archivedIndex != -1) {
				const newArchived = [...state.orders.Archived]
				newArchived[archivedIndex] = { ...newArchived[archivedIndex], status }
				state.orders.Archived = newArchived
			}
		})
		builder.addCase(overrideOrderStatus.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			const { updatedOrder: { status }, statusLog }: any = data
			delete data.contents
			state.activeOrder = { ...state.activeOrder, status, logs: [statusLog, ...state.activeOrder.logs] }
			const acitveIndex = state.orders.Active.findIndex((order: Order) => state.activeOrder.orderID == order.orderID)
			if (acitveIndex != -1) {
				const newActive = [...state.orders.Active]
				newActive[acitveIndex] = { ...newActive[acitveIndex], status }
				state.orders.Active = newActive
			}
			const archivedIndex = state.orders.Archived.findIndex((order: Order) => state.activeOrder.orderID == order.orderID)
			if (archivedIndex != -1) {
				const newArchived = [...state.orders.Archived]
				newArchived[archivedIndex] = { ...newArchived[archivedIndex], status }
				state.orders.Archived = newArchived
			}
		})
		builder.addCase(commentOnOrder.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			state.activeOrder = { ...state.activeOrder, comments: [...state.activeOrder.comments, data] }
		})
		builder.addCase(refundOrder.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			const { status }: any = data
			const acitveIndex = state.orders.Active.findIndex((order: Order) => state.activeOrder.orderID == order.orderID)
			if (acitveIndex != -1) {
				const newActive = [...state.orders.Active]
				newActive[acitveIndex] = { ...newActive[acitveIndex], refunded: true, status }
				state.orders.Active = newActive
			}
			state.activeOrder = { ...state.activeOrder, refunded: true, status }
		})
	}
})

export const { clearActiveOrder, updateOrderStatus, setOrderSearch } = ordersSlice.actions
export default ordersSlice.reducer

