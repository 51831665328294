import React, { SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../Components/Popup/Popup'
import ProductCard from '../../../Pages/Products/Support/ProductCard/ProductCard'
import { Product, removeProductFromArchive } from '../../../Redux/Slices/productsSlice'
import './DeleteProductsPopup.css'

interface DeleteProductsPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
}

export default function DeleteProductsPopup({ visible, setVisible }: DeleteProductsPopupProps) {
	const dispatch = useDispatch()
	const deletedProducts: Product[] = useSelector((s: any) => s.product.deletedList)

	const { role } = useSelector((s: any) => s.activeUser.data)

	const removeFromArchive = (product: Product) => {
		const { productID } = product
		dispatch(removeProductFromArchive({ productID }))
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='Deleted Items'
			className='DeleteProductsPopup thinContainer'
		>
			
			<div className="story body">
				{!deletedProducts.length ? (
					<div className="NoDeletedProductsMessage">No deleted items</div>
				) : null}
				<div className="ProductContainer">
					{deletedProducts.map((product) => {
						return (
							<div key={product.productID}>
								<ProductCard
									
									product={product}
								/>
								{role == 'owner' ? (
									<FreeButton
										className="RemoveFromListButton"
										onClick={() => removeFromArchive(product)}
									>
										Return item to list
									</FreeButton>
								) : null}
							</div>
						)
					})}
				</div>
			</div>
			
		</Popup>
	)
}
