import moment from 'moment'
import React from 'react'
import { Order, statusBadgeColorMap } from '../../../../../Redux/Slices/orderSlice'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import './OrderHistory.css'

export default function OrderHistory({ order }: { order: Order }) {
	const { logs = [] } = order
	return (
		<div className='OrderHistory'>
			<CollapsibleCard
				expandedContent={
					<div className="OrderStatusCardContainer">
						{logs.map((log: any) => {
							return (
								<div
									key={log.id}
									className="OrderStatusCard"
								>
									<div>
										<div style={{ color: statusBadgeColorMap[log.newValue] }}>{log.newValue}</div>
										<div>{log.name || 'Skatescribe Bot'}</div>
									</div>
									<div className="OrderStatusCardTime">
										<div>{moment(log.changeDate).format('MMM Do, YYYY')}</div>
										<div>{moment(log.changeDate).format('hh:mm A')}</div>
									</div>
								</div>
							)
						})}
					</div>
				}
			>
                Order History
			</CollapsibleCard>
		</div>
	)
}
