import React, { SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from '../../Components/DatePicker/DatePicker'
import Popup from '../../Components/Popup/Popup'
import { statusBadgeColorMap } from '../../Redux/Slices/orderSlice'
import './FilterPopup.css'

interface FilterPopupProps {
	advancedFilters: any
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
	onSubmit: Function
}

export default function FilterPopup({ advancedFilters = {}, visible, setVisible, onSubmit }: FilterPopupProps) {

	const validStatuses: string[] = useSelector((s: any) => s.order.validStatuses)
	const [selectedStatusMap, setSelectedStatusMap] = useState<any>({})
	const [dateType, setDateType] = useState<String>('Before')
	const [beforeDate, setBeforeDate] = useState<any>(new Date())
	const [afterDate, setAfterDate] = useState<any>(new Date())
	const [fromDate, setFromDate] = useState<any>(new Date())
	const [toDate, setToDate] = useState<any>(new Date())
	const [customerFilters, setCustomerFilters] = useState<any>({})

	useEffect(() => {
		if (visible) {
			const { statuses = [], dateType, beforeDate, afterDate, fromDate, toDate, firstName, lastName, email, phone } = advancedFilters
			const selectedStatusMap: any = {}
			statuses.map((status: string) => {
				selectedStatusMap[status] = true
			})
			const yesterday = new Date()
			yesterday.setDate(yesterday.getDate() - 1)
			setSelectedStatusMap(selectedStatusMap)
			setDateType(dateType || 'Before')
			setBeforeDate(beforeDate || new Date())
			setAfterDate(afterDate || yesterday)
			setFromDate(fromDate || yesterday)
			setToDate(toDate || new Date())
			setCustomerFilters({ firstName, lastName, email, phone })
		}
	}, [visible])
	
	const handleRadioChange = (type: String) => {
		setDateType(type)
	}

	const handleSubmit = () => {
		let data: any = {
			statuses: Object.keys(selectedStatusMap),
			dateType,
			beforeDate,
			afterDate,
			fromDate,
			toDate,
			...customerFilters
		}
		onSubmit(data)
	}

	const changeHandler = (e: any) => {
		setCustomerFilters({ ...customerFilters, [e.target.name]: e.target.value })
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='Filtered Search'
			className='FilterPopup thinContainer'
		>
			
			<div className="story body">
				<div className="FilterContainer">
					<div className="TypeContainer">
						<div className="TypeHeader">
						Status:
						</div>
						<div
							className="StatusCheckbox"
							onClick={() => {
								setSelectedStatusMap({})
							}}
						>
							<input
								type="checkbox"
								className="LocationsSelectionCheckbox"
								checked={!Object.keys(selectedStatusMap).length}
								readOnly
							/>
							<div>All</div>
						</div>
						{validStatuses.map((status) => {
							return (
								<div
									key={status}
									className="StatusCheckbox"
									onClick={() => {
										const checked = Boolean(!selectedStatusMap[status])
										if (checked) {
											setSelectedStatusMap({
												...selectedStatusMap,
												[status]: true
											})
										} else {
											delete selectedStatusMap[status]
											setSelectedStatusMap({
												...selectedStatusMap,
											})
										}
									}}
								>
									<input
										type="checkbox"
										className="LocationsSelectionCheckbox"
										checked={Boolean(selectedStatusMap[status])}
										readOnly
									/>
									<div style={{ color: statusBadgeColorMap[status] }}>{status}</div>
								</div>
							)
						})}
					</div>
					<div className="TypeContainer">
						<div className="TypeHeader">
							Date:
						</div>
						<div className="DateFilter">
							<div
								onClick={() => {
									handleRadioChange('Before')
								}}
							>
								<div
									className="DateTypeCheckBox"
								>
									<input
										type="radio"
										checked={dateType == 'Before'}
										readOnly
									/>
									Before:
								</div>
								<div className="DatePickerContainer">
									<DatePicker
										date={beforeDate}
										setDate={(date: Date) => setBeforeDate(date)}
									/>
								</div>
							</div>

							<div
								onClick={() => {
									handleRadioChange('After')
								}}
							>
								<div
									className="DateTypeCheckBox"
								>
									<input
										type="radio"
										checked={dateType == 'After'}
										readOnly
									/>
									After:
								</div>
								<div className="DatePickerContainer">
									<DatePicker
										date={afterDate}
										setDate={(date: Date) => setAfterDate(date)}
									/>
								</div>
							</div>


							<div
								className="FromToDateInputContainer"
								onClick={() => {
									handleRadioChange('Range')
								}}
							>
								<div>
									<input
										type="radio"
										checked={dateType == 'Range'}
										readOnly
									/>
								</div>
								<div className="FromToDateInputs">
									<div>
										<div>From:</div>
										<DatePicker
											date={fromDate}
											setDate={(date: Date) => setFromDate(date)}
										/>
									</div>
									<div >
										<div>To:</div>
										<DatePicker
											date={toDate}
											setDate={(date: Date) => setToDate(date)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<form className="TypeContainer" autoComplete='off' style={{ paddingTop: 25 }}>
					<div className="TypeHeader">Customer:</div>
					<div className="CustomerFilterContainer">
						<div className='HalfFilter'>
							<input
								className="input wide"
								value={customerFilters.firstName}
								placeholder='First Name'
								name="firstName"
								onChange={changeHandler}
							/>
						</div>
						<div className='HalfFilter'>
							<input
								className="input wide"
								value={customerFilters.lastName}
								placeholder='Last Name'
								name="lastName"
								onChange={changeHandler}
							/>
						</div>
						<div className='HalfFilter'>
							<input
								className="input wide"
								value={customerFilters.email}
								placeholder='Email'
								name="email"
								onChange={changeHandler}
							/>
						</div>
						<div className='HalfFilter'>
							<input
								className="input wide"
								value={customerFilters.phone}
								placeholder='Phone Number'
								name="phone"
								onChange={changeHandler}
							/>
						</div>
					</div>
				</form>
				<div className="action">
					<button className="button neutral" onClick={() => handleSubmit()}>
						Search Orders
					</button>
				</div>
			</div>
			
		</Popup>
	)
}
