import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import logoURL from '../../Assets/graphics/logo.png'
import logoMobile from '../../Assets/graphics/logo.svg'
import auth from '../../Handlers/auth'
import SignInPopup from '../../Popups/SignInPopup/SignInPopup'
import LoggedInOnly from '../LoggedInOnly/LoggedInOnly'
import LoggedOutOnly from '../LoggedOutOnly/LoggedOutOnly'
import InternalContainer from '../InternalContaier/InternalContainer'
import './Navbar.css'

export default function Navbar() {
	const history = useHistory()
	const [showSignIn, setShowSignIn] = useState<boolean>(false)
	const { activeLocation } = useSelector((s: any) => s.activeUser)
	const isOwner = useSelector((s: any) => s.activeUser.data.role == 'owner')

	const onSignOut = () => {
		auth.logout()
		history.replace('/')
	}

	return (
		<>
			<SignInPopup
				visible={showSignIn}
				setVisible={setShowSignIn}
			/>

			<InternalContainer className='Navbar flexY'>
				<div className="logo desktopOnly">
					<Link to='/'>
						<img src={logoURL} alt="logo" draggable='false' />
					</Link>
				</div>

				<div className="flexY noDesktop">
					<div className="logo">
						<Link to='/'>
							<img src={logoMobile} alt="logo" draggable='false' />
						</Link>
					</div>
				</div>

				{activeLocation ? <div className="ActiveLocation">
					{activeLocation.locationID} - {activeLocation.title}
				</div> : null }
				<div className="controls flexY">
					<LoggedOutOnly>
						<button className="link button plain NavbarItem" onClick={() => setShowSignIn(true)}>
							Log In
						</button>
					</LoggedOutOnly>

					<LoggedInOnly>
						<div className="noMobile">

							{activeLocation ? <Link to='/Orders' className='link'>
								Orders
							</Link> : null}
							{isOwner ? <Link to='/Employees' className='link'>
								Employees
							</Link> : null}
							<Link to='/Products' className='link'>
								Products
							</Link>
							<Link to='/Customers' className='link'>
								Customers
							</Link>
						</div>
						<button className="button plain negative NavbarItem" onClick={onSignOut}>
							Sign Out
						</button>
					</LoggedInOnly>
				</div>
			</InternalContainer>
		</>
	)
}
