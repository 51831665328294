import React from 'react'
import InternalContainer from '../InternalContaier/InternalContainer'

interface MainContainerProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function MainContainer({ children, className, style }: MainContainerProps) {
	return (
		<div className='MainContainer flexX'>
			<InternalContainer className={className} style={style}>
				{children}
			</InternalContainer>
		</div>
	)
}
