import React from 'react'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import { Customer } from '../../../../../Redux/Slices/customerSlice'
import './CustomerOrders.css'
import CustomerOrder from './Support/CustomerOrder'

export default function CustomerOrders({ customer }: { customer: Customer }) {
	const { orders = [] } = customer

	return (
		<div className='CustomersOrders'>
			<CollapsibleCard
				expandedContent={
					<div className="OrdersContainer">
						{orders.map((order: any) => {
							return (
								<CustomerOrder
									key={order.orderID}
									order={order}
								/>
							)
						})}
					</div>
				}
			>
                Orders&nbsp;({orders.length})
			</CollapsibleCard>
		</div>
	)
}
