import React from 'react'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import CreditCardInfo from '../../../../../Components/CreditCardInfo/CreditCardInfo.'
import LabelText from '../../../../../Components/LabelText/LabelText'
import { Customer } from '../../../../../Redux/Slices/customerSlice'
import './SavedCards.css'

export default function SavedCards({ customer }: { customer: Customer }) {
	const { cards = [] } = customer
	
	return (
		<div className='SavedCards'>
			<CollapsibleCard
				expandedContent={
					<div className="SavedCardsContainer">
						{cards.map((card: any) => {
							return (
								<div className="CardContainer" key={card.card_id}>
									<div className="HolderName">{customer.playerFirstName} {customer.playerLastName}</div>
									<CreditCardInfo
										cardDetails={card}
									/>
									<LabelText
										label='Postal Code'
										text={customer.postalCode}
									/>
								</div>
							)
						})}
					</div>
				}
			>
                Saved Cards
			</CollapsibleCard>
		</div>
	)
}
