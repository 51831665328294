import React from 'react'

export default function LabelText({ label, text }: { label: string, text: string }) {
	return (
		<div>
			<span className="Label">
				{label}:
			</span>
			<span className="Value">
				{text}
			</span>
		</div>
	)
}
