import React, { useState } from 'react'
import './CommentView.css'
import { FreeButton } from 'react-ui-scaffold'
import { commentOnOrder } from '../../../Redux/Slices/orderSlice'
import { useDispatch } from 'react-redux'

interface CommentViewProps {
    close: Function
    orderID: string
}

export default function CommentView({ close, orderID }: CommentViewProps) {
	const dispatch = useDispatch()

	const [comment, setComment] = useState('')

	const onCommentSubmit = () => {
		if (!comment) {
			return close()
		}
		dispatch(commentOnOrder({
			orderID,
			comment,
		}))
		close()
	}

	return (
		<div className="CommentView">
			<div className="CommentContainer">
                Additional Comments
				<textarea
					className="input wide"
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					placeholder="Please type your comments here."
				/>
			</div>
			<div className="action">
				<FreeButton onClick={onCommentSubmit} className="OkayButton hollow">No Comment</FreeButton>
				<FreeButton onClick={onCommentSubmit} className="OkayButton" disabled={!comment}>Add a Comment</FreeButton>
			</div>
		</div>
	)
}
