import React, { ChangeEventHandler } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import auth from '../../../../Handlers/auth'

interface LogInScreenProps {
	changeHandler: ChangeEventHandler<HTMLInputElement>
	data: any
	loading: any
	setActiveTab: Function
	setMessage: Function
	setVisible: Function
	setLoading: Function
}

export default function LogInScreen({ changeHandler, data, loading, setActiveTab, setMessage, setVisible, setLoading }: LogInScreenProps) {

	function login() {
		setLoading({ normal: true })

		auth.login(data.email, data.password).then(() => {
			setVisible(false)
		}).catch(err => {
			setMessage({
				text: err,
				color: 'var(--negativeAccentColor)'
			})
		})

		setLoading({})
	}

	return (
		<div className='LogIn'>
			<div className="story">
				<label htmlFor="Email">Email</label>
				<input
					type="text"
					className="input wide"
					placeholder='example@email.com'
					data-htmlFor='email'
					name='email'
					value={data.email}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Password</label>
				{/* @ts-ignore */}
				<input
					type="password"
					className="input wide"
					placeholder='Password'
					data-htmlFor='password'
					name='password'
					value={data.password}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<FreeButton className="button wide" loading={loading.normal} onClick={login}>
					Log In
				</FreeButton>
				<div className="story">
					<FreeButton className="button plain wide neutral" onClick={() => setActiveTab('forgot')}>
						<b>Forgot your password?</b>
					</FreeButton>
				</div>
			</div>

			<div className="story" style={{ textAlign: 'center', fontWeight: 'bold' }}>
				OR
			</div>

			<div className="story">
				<FreeButton className="button plain wide neutral" onClick={() => { setActiveTab('create') }}>
					<b>Not a member yet? Sign up</b>
				</FreeButton>
			</div>

		</div>
	)
}
