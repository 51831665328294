import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setScreenSize } from '../Redux/Slices/viewSlice'

export function useScreenSize() {
	//Responsiveness
	const screenSize = useSelector((s: any) => s.view.screenSize)
	const dispatch = useDispatch()

	useEffect(() => {

		function getScreenSize(w: number) {

			if (w > 1200) {
				return 'desktop'

			} else if (w > 950) {
				return 'tablet'
			}
			else {
				return 'mobile'
			}

		}

		const listener = () => {
			dispatch(setScreenSize(getScreenSize(window.innerWidth)))
		}


		window.addEventListener('resize', listener)
		listener()

		return () => window.removeEventListener('resize', listener)
	}, [])

	return { screenSize }
}
