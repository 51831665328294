import { fetchAdmin, setAccount } from '../Redux/Slices/activeUserSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { fetchValidStatuses } from '../Redux/Slices/orderSlice'


//Listens for any changes in auth state and dispateches actions to the redux store
export function useSkatescribeAuthState(){
	const dispatch = useDispatch()
	
	useEffect(() => {
		const unsubAuth = firebase.auth().onAuthStateChanged(async (user) => {
			const cleanUser = JSON.parse(JSON.stringify(user))
			
			firebase.auth().currentUser?.getIdToken().then(() => {
				// if(process.env.NODE_ENV==='development'){console.log(token)}
			}).catch(console.error)
			
			dispatch(setAccount(cleanUser))
			if (user) {
				dispatch(fetchValidStatuses())
				dispatch(fetchAdmin())
			}
		})

		return unsubAuth
	}, [])
}
