import React, { ChangeEventHandler } from 'react'
import beam from '../../../../Handlers/beam'
import { FreeButton } from 'react-ui-scaffold'

interface CreateAccountProps {
	changeHandler: ChangeEventHandler<HTMLInputElement>
	data: any
	loading: any
	setActiveTab: Function
	setMessage: Function
	setLoading: Function
	setData: Function
}

export default function CreateAccount({
	changeHandler,
	data,
	loading,
	setActiveTab,
	setMessage,
	setLoading,
	setData
}: CreateAccountProps){
	
	async function create(){
		
		if (data.createPassword !== data.confirmPassword){
			setMessage({
				color: 'var(--negativeAccentColor)',
				text: "Passwords don't match!"
			})
			
			return
		}
		
		setLoading(true)
		
		await beam.post('/api/admin/createAdmin', {
			name: data.name,
			password: data.createPassword,
			email: data.createEmail,
			
		}).then(({ response }) => {
			
			setActiveTab('login')
			setMessage({ text: 'Created account successfully!', color: 'var(--positiveAccentColor)' })
			setData({ ...data, email: response.user.account.email, password: '' })
			
		}).catch(err => {
			console.log(err)
			setMessage({ text: err.response?.error, color: 'var(--negativeAccentColor)' })
		})
		
		setLoading(false)
	}
	
	
	return (
		<div className='CreateAccount'>
			
			<div className="story">
				<label htmlFor="Email">Full Name</label>
				<input
					type="text"
					className="input wide"
					placeholder='Firstname LastName'
					data-htmlFor='name'
					name='name'
					value={data.name}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label htmlFor="Email">Email</label>
				<input
					type="text"
					className="input wide"
					placeholder='example@email.com'
					data-htmlFor='new email'
					name='createEmail'
					value={data.createEmail}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Password</label>
				<input
					type="password"
					className="input wide"
					placeholder='Password'
					data-htmlFor='new password'
					name='createPassword'
					value={data.createPassword}
					onChange={changeHandler}
				/>
			</div>
			
			<div className="story">
				<label htmlFor="Email">Confirm Password</label>
				<input
					type="password"
					className="input wide"
					placeholder='Password'
					data-htmlFor='new password'
					name='confirmPassword'
					value={data.confirmPassword}
					onChange={changeHandler}
				/>
			</div>
			

			<div className="story">
				<FreeButton className="button wide" loading={loading.normal} onClick={create}>
					Create Account
				</FreeButton>
			</div>
			
			<div className="line" style={{ padding: '10px 0', marginTop: '30px' }}></div>
			

			<div className="story" style={{ textAlign: 'center', fontWeight: 'bold' }}>
				OR
			</div>

			<div className="story">
				<FreeButton className="button plain wide neutral" onClick={() => { setActiveTab('login') }}>
					<b>Have an account? Log in</b>
				</FreeButton>
			</div>
		</div>
	)
}
