import React, { SetStateAction } from 'react'
import Popup from '../../Components/Popup/Popup'
import './ConfirmEmployeeRemove.css'
import { Admin } from '../../Redux/Slices/employeeSlice'

interface ConfirmEmployeeRemoveProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
	employee: Admin
	onConfirm: Function
}

export default function ConfirmEmployeeRemove({ visible, setVisible, employee, onConfirm = () => { } }: ConfirmEmployeeRemoveProps) {

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='Remove Employee'
			className='ConfirmEmployeeRemove'
		>
			
			<div className="story body">
				<div>
					Are you sure you want to remove {employee.name} from the list of employees
				</div>
				<div className="actions">
					<button className="button hollow" onClick={() => onConfirm()}>
						Yes, Remove Employee
					</button>
					<button
						className="button neutral"
						onClick={() => setVisible(false)}
					>
						Cancel
					</button>
				</div>
			</div>
			
		</Popup>
	)
}
