import React from 'react'
import { Order, statusBadgeColorMap } from '../../../../../../Redux/Slices/orderSlice'
import moment from 'moment'
import './CustomerOrder.css'
import { useHistory } from 'react-router-dom'


interface CustomerOrderProps {
    order: Order;
}

export default function CustomerOrder({
	order,
}: CustomerOrderProps) {
	const history = useHistory()
	return (
		<div
			className='CustomerOrderCard'
			onClick={() => history.push(`/Orders/${order.orderID}`)}
		>
			<div className="OrderCardDetails">
				<div>Order #{order.shortID} ({order.itemCount} items)</div>
				<div>{order.placedBy}</div>
				<div>{order.contactFirstName} {order.contactLastName}</div>
			</div>
			<div className="OrderCardDetails StatusDate">
				<div>{moment(order.placedDate).format('MMM D, YYYY')}</div>
				<div style={{ color: statusBadgeColorMap[order.status] }} className="OrderCardDetailsStatus">{order.status}</div>
			</div>
		</div>
	)
}
