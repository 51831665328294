import React, { SetStateAction } from 'react'
import Popup from '../../Components/Popup/Popup'
import './ManageBasicPresetsPopup.css'
import { useSelector } from 'react-redux'
import { BasicPreset } from '../../Redux/Slices/basicPresetSlices'
import PresetCard from './Support/PresetCard/PresetCard'

interface ManageBasicPresetsPopupProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
}

export default function ManageBasicPresetsPopup({ visible, setVisible }: ManageBasicPresetsPopupProps) {
	const presets = useSelector((s: any) => s.basicPreset.presets)

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title="Basic Presets Specs"
			className='ManageBasicPresetsPopup'
		>
			<div className="story body PresetsContainer">
				{presets.map((preset: BasicPreset) => {
					return (
						<PresetCard
							key={preset.basicPresetID}
							preset={preset}
						/>
					)
				})}
			</div>

		</Popup>
	)
}
