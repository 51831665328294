import React, { useState } from 'react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { Order, statusBadgeColorMap } from '../../../../../Redux/Slices/orderSlice'
import ConfirmStatusChangePopup from '../../../../../Popups/ConfirmStatusChangePopup/ConfirmStatusChangePopup'
import { MenuButton } from 'react-ui-scaffold'

export default function StatusDropdown({ order }: { order: Order }) {
	const [confirmStatusChangePopupOpen, setConfirmStatusChangePopupOpen] = useState(false)
	const [selectedStatus, setSelectedStatus] = useState('')
	const validStatuses: string[] = useSelector((s: any) => s.order.validStatuses)
	const BurgerMenu = (
		<>
			{validStatuses.map(status => (
				<button
					key={status}
					className="button wide"
					onClick={() => {
						setConfirmStatusChangePopupOpen(true)
						setSelectedStatus(status)
					}}
				>
					{status}
				</button>
			))}
		</>
	)
	const { status }: { status: string } = order

	return (
		<>
			<ConfirmStatusChangePopup
				visible={confirmStatusChangePopupOpen}
				setVisible={setConfirmStatusChangePopupOpen}
				status={selectedStatus}
				orderID={order.orderID}
			/>
			<div className="StatusDropdown">
				<span className="StatusTitle">Status:</span>
				<MenuButton menuContent={BurgerMenu} menuStyle={{ width: 220 }}>
					<div className="ActionDropdown" style={{ backgroundColor: statusBadgeColorMap[status] }}>
						<div className="DropdownTitle">
							{order.status}
							<span className="CaretIcon">
								<FontAwesomeIcon
									icon={faCaretDown}
									color='#fff'
									size="lg"
								/>
							</span>
						</div>
					</div>
				</MenuButton>
			</div>
		</>
	)
}
