import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'

export const fetchAdmin = createAsyncThunk('admin/fetchAdmin', async () => {
	const fetchRes = await beam.get('/api/admin/fetchAdmin').catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data
	}
})

export const saveLocation = createAsyncThunk('admin/saveLocation', async ({ locationID }: { locationID: number }) => {
	const updateRes = await beam.post('/api/admin/updateLastUsedLocation', { locationID }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: updateRes.response.data
	}
})

interface Admin {
	adminEmail: string;
	status: string;
	role: string;
	name: string;
	addedBy: string;
	addedDate: Date;
	authID?: string;
}

//Slice
const activeUserSlice = createSlice({
	name: 'activeUser',
	
	initialState: {
		status: 'Loading',
		account: {},
		data: {
			availableLocations: [],
		},
		activeLocation: null,
		hasNewOrder: false
	},
	
	reducers: {
		setAccount: (state: any, action: any) => {
			if (action.payload?.uid){
				state.account = action.payload
				
			} else {
				state.account = {}
				state.status = 'Logged Out'
			}
		},
		setHasNewOrder: (state: any, action: any) => {
			state.hasNewOrder = action.payload
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchAdmin.fulfilled, (state: any, { payload }: any) => {
			const admin: Admin = { ...state.data, ...payload.data }
			const { lastUsedLocation, availableLocations = [] } = payload.data
			state.data = admin
			state.status = 'Logged In'
			if (lastUsedLocation){
				const location = availableLocations.find((element: any) => element.locationID == lastUsedLocation)
				if (location) {
					state.activeLocation = location
				}
			}
		})
		builder.addCase(fetchAdmin.rejected, (state: any) => {
			state.status = 'Logged Out'
		})
		builder.addCase(saveLocation.pending, (state: any, { meta: { arg } }: any) => {
			state.activeLocation = arg
			state.hasNewOrder = false
		})
	}
})

export const { setAccount, setHasNewOrder } = activeUserSlice.actions
export default activeUserSlice.reducer
