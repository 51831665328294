import React, { useState } from 'react'
import LoggedInOnly from '../../Components/LoggedInOnly/LoggedInOnly'
import { Page } from 'react-ui-scaffold'
import './Customers.css'
import MainContainer from '../../Components/MainContainer/MainContainer'
import InternalContainer from '../../Components/InternalContaier/InternalContainer'
import SideBar from './Support/SideBar/SideBar'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrderDetails from './Support/CustomerDetails/CustomerDetails'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchCustomer, fetchCustomerCount, fetchCustomers } from '../../Redux/Slices/customerSlice'

export default function Customers({ match }:any) {
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [search, setSearch] = useState('')

	useEffect(() => {
		if (match.params.playerEmail) {
			dispatch(fetchCustomer({ playerEmail: match.params.playerEmail }))
		}
	}, [])

	const onSearch = (e: any) => {
		setSearch(e.target.value)
	}

	const dispatchSearch = () => {
		dispatch(fetchCustomers({ page, search }))
		dispatch(fetchCustomerCount({ search }))
	}

	useEffect(() => {
		dispatchSearch()
	}, [page])

	useEffect(() => {
		if (page != 1) {
			return setPage(1)
		}
		dispatchSearch()
	}, [search])

	return (
		<Page className='Customers'>
			<LoggedInOnly>
				<MainContainer>
					<InternalContainer className="SideBarSearchContainer">
						<div className="SideBarContainer">
							<div className="CustomerPageTitle">Skatescribe Customers</div>
							<SideBar
								setPage={setPage}
								page={page}
								search={search}
							/>
						</div>
						<div className="SearchContainer">
							<div className="SearchBox">
								<FontAwesomeIcon icon={faSearch} color="#b7b7b7" className="SearchIcon" />
								<input
									type="text"
									className="input SearchInput"
									value={search}
									onChange={onSearch}
									placeholder="Search for a customer name, email, or phone number"
								/>
							</div>
						</div>
					</InternalContainer>
						
					<OrderDetails />
				</MainContainer>

			</LoggedInOnly>
		</Page>
	)
}
