import React, { useState, SetStateAction, useEffect, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../Components/Popup/Popup'
import ImageUploadInput from '../Support/ImageUploadInput/ImageUploadInput'
import purchaseBladeImage from '../../../Assets/graphics/agility-preset.svg'

import './AddProductPopup.css'
import { createProduct } from '../../../Redux/Slices/productsSlice'

interface AddProductPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
}

const defaultData = {
	inStock: 'In Stock'
}

export default function AddProductPopup({ visible, setVisible }: AddProductPopupProps) {
	const dispatch = useDispatch()
	const [imageData, setImageData] = useState<any>(null)
	
	const [error, setError] = useState(null)
	const [data, setData] = useState<any>(defaultData)
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (visible) {
			setData(defaultData)
			setImageData(null)
			setLoading(false)
			setError(null)
		}
	}, [visible])

	const changeHandler = (e: ChangeEvent<HTMLInputElement| HTMLSelectElement>) => {
		setData({ ...data, [e.target.name]: e.target.value })
	}
	
	const onSubmit = () => {
		setLoading(true)
		const formData = new FormData()
		Object.keys(data).map((key: string) => {
			formData.append(key, data[key])
		})
		formData.set('type', 'Purchase')
		formData.set('image', imageData?.file)
		formData.set('inStock', (data.inStock == 'In Stock').toString())
		formData.set('price', (parseFloat(data.price) * 100).toFixed(0))
		dispatch(createProduct({
			data: formData,
			callback: (err: any) => {
				setLoading(false)
				if (err) {
					return setError(err)
				}
				setVisible(false)
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='+Add a Product'
			className='AddProductPopup thinContainer'
		>
			<div className="story error">
				{error}
			</div>
			
			<div className="story body ProductAddForm">
				<ImageUploadInput
					className="ChangeProductImage"
					onImageUpload={setImageData}
					render={(ref: any) => (
						<>
							<div className="ActionContainer">
								<button className="button neutral plain viewAll" onClick={() => ref.current.click()}>
									Change Picture
								</button>
							</div>
							<img src={imageData?.imageURL || purchaseBladeImage} className="ProductImagePreview" />
						</>
					)}
				/>
				<div className="AddForm">
					<input
						type="text"
						name="name"
						className="input wide"
						value={data.name || ''}
						onChange={changeHandler}
						placeholder="Product Name"
					/>
					<div className="subStory wide" >
						<label>Brand:</label>
						<input
							type="text"
							name="brand"
							className="input wide"
							value={data.brand || ''}
							onChange={changeHandler}
							placeholder="eg. Bauer Tuuk or CCM"
						/>
					</div>
					<div className="subStory wide" >
						<label>SKU (COMPANY-MODEL):</label>
						<input
							type="text"
							name='skuCode'
							className="input wide"
							value={data.skuCode || ''}
							onChange={changeHandler}
							placeholder="CCMXS-SB"
						/>
					</div>
					<div className="subStory wide" >
						<label>Price:</label>
						<div className="PriceInput">
							$
							<input
								type="number"
								name='price'
								className="input"
								value={data.price || ''}
								onChange={changeHandler}
								onBlur={(e: any) => {
									setData({
										...data,
										[e.target.name]: (Math.round((parseFloat(e.target.value) + Number.EPSILON)* 100) / 100).toFixed(2)
									})
									
								}}
							/>
							CAD
						</div>
					</div>
					<div className="subStory wide" >
						<label>Availability:</label>
						<select
							name='inStock'
							className='input wide'
							value={data.inStock || ''}
							onChange={changeHandler}
						>
							<option value="In Stock">
								In Stock
							</option>
							<option value="Out of Stock">
								Out of Stock
							</option>
						</select>
					</div>
				</div>
			</div>
			<div className="DoneButtonContainer">
				<FreeButton
					className="DoneButton"
					onClick={() => onSubmit()}
					loading={loading}
				>
					Done
				</FreeButton>
			</div>
			
		</Popup>
	)
}
