import React, { useMemo, useState } from 'react'
import { Product, ProductSku, setActiveOrder } from '../../../../Redux/Slices/productsSlice'
import { formatCurrency } from '../../../../Utils/formatUtils'
import purchaseBladeImage from '../../../../Assets/graphics/agility-preset.svg'
import './ProductCard.css'
import EditProductPopup from '../../../../Popups/ProductPopups/EditProductPopup/EditProductPopup'
import { useDispatch } from 'react-redux'

interface ProductCardProps {
	product: Product
	onDelete?: Function
}

export default function ProductCard({ product, onDelete }: ProductCardProps) {
	const dispatch = useDispatch()
	
	const [editProductVisible, setEditProductVisible] = useState(false)
	const outOfStockSkus = useMemo(() => {
		if (!product.skus) {
			return []
		}
		return product.skus.filter((sku: ProductSku) => !sku.inStock)
	}, [product])
	
	return (
		<div className="ProductCardContainer">
			<div
				className="ProductCard"
				onClick={() => {
					setEditProductVisible(true)
					dispatch(setActiveOrder(product))
				}}
			>
				<EditProductPopup
					visible={editProductVisible}
					setVisible={setEditProductVisible}
					onDelete={onDelete}
				/>
				<div className="hemi ProductCardTitle">
					{product.name}
				</div>
				<img src={product.imageURL || purchaseBladeImage} alt="purchase-blade" draggable='false' className="ProductImage" />
				{product.skuCode ? (
					<div className="flexY SkuCodeContainer">
						SKU:&nbsp;
						<div className="SkuCode">
							{product.skuCode}
						</div>
					</div>
				): null}
				<div className="flexY PriceContainer">
					Price:&nbsp;
					<div className="Price">
						{formatCurrency(product.price)} CAD
					</div>
				</div>
				{!product.inStock ? (
					<div className="InOutStockIndicator">
						Temporarily Out of Stock
					</div>
				) : null}
				{product.inStock && outOfStockSkus.length ? (
					<div className="InOutStockIndicator">
						Sizes Temporarily Out of Stock
						<div>
							{outOfStockSkus.map((sku) => {
								return sku.name
							}).join(', ')}
						</div>
					</div>
				) : null}
			</div>
		</div>
	)
}
