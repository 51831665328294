import React from 'react'
import './SideBar.css'
import { useSelector } from 'react-redux'
import CustomerCard from '../SideBarSupport/CustomerCard/CustomerCard'
import { pageSize } from '../../../../Redux/Slices/orderSlice'

interface SideBarProps {
	page: number;
	setPage: Function;
	search?: string;
}

export default function SideBar({
	page,
	setPage,
	search
}: SideBarProps) {
	const customers = useSelector((s: any) => s.customer.customers || [])
	const customerCount = useSelector((s: any) => s.customer.count || 0)
	const loading = useSelector((s: any) => s.customer.loading || 0)
	
	return (
		<div
			className="SideBar"
		>
			{loading ? <div className='CustomerSideBarTitle'>Loading...</div> : null}
			{!loading && (
				<>
					<div className='CustomerSideBarTitle'>
						{search ?
							`Search Results (${customerCount})` :
							`All Customers (${customerCount})`
						}
					</div>
					{customers.map((customer: any) => {
						return (
							<CustomerCard
								key={customer.playerEmail}
								customer={customer}
							/>
						)
					})}
				</>
			)}
			{pageSize * page < customerCount ? (
				<div className="LoadMore" onClick={() => setPage(page + 1)}>Load more</div>
			) : null}
		</div>
	)
}
