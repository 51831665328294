import firebase from 'firebase/app'
import 'firebase/auth'

interface FetchRequestParams {
  method: 'GET' | 'POST' | 'UPDATE' | 'OPTIONS' | 'DELETE' | 'PUT';
  endpoint?: string;
  body?: any;
  headers?: any;
  customConfig?: any;
}

async function createFetchRequest({ method, endpoint = '', body, headers = { 'Content-Type': 'application/json' }, customConfig }: FetchRequestParams) {

	//if the url is a subURL (starts with /) append the base url automatically. otherwise use it as is
	const baseURL = endpoint.startsWith('/') ? process.env.REACT_APP_SERVER_URL : ''
	const token = await firebase.auth().currentUser?.getIdToken().catch(() => '')

	const fetchConfig = {
		headers: {
			...headers
		},
		method,
		...customConfig,
	}

	if (token) {
		fetchConfig.headers.Authorization = token
	}

	if (body) {
		fetchConfig.body = body instanceof FormData ? body : JSON.stringify(body)
	}

	try {

		const response = await fetch(`${baseURL}${endpoint}`, fetchConfig)

		if (response.ok) {
			return {
				status: response.status,
				response: await response.json()
			}

		} else {

			const errorMessage = {
				status: response.status,
				request: { ...fetchConfig, endpoint },
				response: await response.json(),
			}

			console.log({ beamError: errorMessage })

			return Promise.reject(errorMessage)
		}

	} catch (err) {

		const errorMessage = {
			request: { ...fetchConfig, endpoint },
			response: { error: 'Network Error' }
		}

		console.log({ beamError: errorMessage })
		return Promise.reject(errorMessage)
	}

}


//Shortcuts to each method

interface RequestResponse {
	status: number;
	response: any;
	data?: any;
}

async function get(endpoint: string, headers?: any): Promise<RequestResponse> {
	return await createFetchRequest({
		method: 'GET',
		endpoint,
		headers
	})
}

async function post(endpoint: string, body?: any, headers?: any): Promise<RequestResponse> {
	return await createFetchRequest({
		method: 'POST',
		endpoint,
		body,
		headers
	})
}

async function del(endpoint: string, body?: any, headers?: any): Promise<RequestResponse> {
	return await createFetchRequest({
		method: 'DELETE',
		endpoint,
		body,
		headers
	})
}

async function put(endpoint: string, body?: any, headers?: any): Promise<RequestResponse> {
	return await createFetchRequest({
		method: 'PUT',
		endpoint,
		body,
		headers
	})
}

const beam = {
	createFetchRequest,
	delete: del,
	post,
	get,
	put,
}

export default beam
