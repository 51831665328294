import React, { useEffect, useMemo, useRef, useState } from 'react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './CollapsibleCard.css'

interface CollapsibleCardProps {
	children: React.ReactChild | React.ReactChild[]
	expandedContent: React.ReactChild
	defaultExpanded?: Boolean
	onOpen?: Function
	onClose?: Function
}

export default function CollapsibleCard({ children, expandedContent, defaultExpanded, onOpen, onClose }: CollapsibleCardProps) {
	const [expanded, setExpanded] = useState(false)
	useEffect(() => {
		setExpanded(Boolean(defaultExpanded))
	}, [])

	const onToggleExpand = () => {
		const newExpanded = Boolean(!expanded)
		setExpanded(newExpanded)
		if (newExpanded) {
			onOpen && onOpen()
		} else {
			onClose && onClose()
		}
	}

	const body = useRef(null)
	const height = useMemo(() => (body?.current as any)?.scrollHeight, [body])

	return (
		<div className={`CollapsibleCard ${expanded ? 'Expanded' : ''}`}>
			<div
				className="CollapsibleCardHeader"
				onClick={() => onToggleExpand()}
			>
				<div>{children}</div>
				<span className="CaretIcon">
					<FontAwesomeIcon
						icon={faCaretDown}
						size="lg"
					/>
				</span>
			</div>
			<div className="CollapsibleCardSeparator" />
			<div className="CollapsibleCardBodyWrapper" style={{ maxHeight: expanded ? height : 0 }}>
				<div className="CollapsibleCardBody" ref={body}>
					{expandedContent}
				</div>
			</div>
		</div>
	)
}
