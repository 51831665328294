import React, { useState, SetStateAction, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton, MenuButton } from 'react-ui-scaffold'
import Popup from '../../../Components/Popup/Popup'
import { createProductSku, ProductSku, updateProduct, updateProductStock } from '../../../Redux/Slices/productsSlice'
import ImageUploadInput from '../Support/ImageUploadInput/ImageUploadInput'
import purchaseBladeImage from '../../../Assets/graphics/agility-preset.svg'
import './EditProductPopup.css'
import ManageSkuRow from './Support/ManageSkuRow/ManageSkuRow'
import ConfirmSkuDeletePopup from '../ConfirmSkuDeletePopup/ConfirmSkuDeletePopup'

interface EditProductPopupProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
	onDelete?: Function
}

export default function EditProductPopup({ visible, setVisible, onDelete }: EditProductPopupProps) {
	const dispatch = useDispatch()

	const { role } = useSelector((s: any) => s.activeUser.data)
	const isOwner = role =='owner'
	const activeProduct = useSelector((s: any) => s.product.activeProduct)
	const [imageData, setImageData] = useState<any>(null)

	const [error, setError] = useState(null)
	const [data, setData] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [fullEditMode, setFullEditMode] = useState<boolean>(false)
	const [size, setSize] = useState<string>('')
	const [deleteSkuConfirmVisible, setDeleteSkuConfirmVisible] = useState(false)
	const [skuToDelete, setSkuToDelete] = useState<ProductSku | null>(null)

	useEffect(() => {
		if (visible) {
			if (activeProduct) {
				setData({
					...activeProduct,
					price: ((activeProduct.price || 0) / 100).toFixed(2),
					inStock: activeProduct.inStock ? 'In Stock' : 'Out of Stock'
				})
			}
		} else {
			setImageData(null)
			setLoading(false)
			setError(null)
			setFullEditMode(false)
		}
	}, [visible, activeProduct])

	const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	const onSubmit = () => {
		setLoading(true)
		const formData = new FormData()
		if (fullEditMode) {
			Object.keys(data).map((key: string) => {
				formData.set(key, data[key])
			})
			formData.set('image', imageData?.file)
			formData.set('price', (parseFloat(data.price) * 100).toFixed(0))
		}
		formData.set('productID', activeProduct.productID.toString())
		formData.set('inStock', (data.inStock == 'In Stock').toString())
		const requestCallback = (err: any) => {
			setLoading(false)
			if (err) {
				return setError(err)
			}
		}
		if (isOwner) {
			dispatch(updateProduct({
				data: formData,
				callback: requestCallback
			}))
		} else {
			dispatch(updateProductStock({
				data: {
					productID: activeProduct.productID.toString(),
					inStock: (data.inStock == 'In Stock').toString()
				},
				callback: requestCallback
			}))
			
		}
	}

	const onAddSize = () => {
		dispatch(createProductSku({
			name: size, productID: activeProduct.productID, callback: (error: any) => {
				if (error) {
					return setError(error)
				}
				setSize('')
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={activeProduct.name}
			action={isOwner ? (
				<MenuButton
					menuContent={
						<>
							<button
								className="button wide"
								onClick={() => {
									setFullEditMode(true)
								}}
							>
                                Edit Item
							</button>
							{activeProduct.status == 'Active' ? (
								<button
									className="button wide"
									onClick={() => {
										onDelete && onDelete()
									}}
								>
                                    Delete Item
								</button>
							) : null}
						</>
					}
				>
					<button className="button neutral plain viewAll" >
                        More
					</button>
				</MenuButton>
			) : null}
			className='EditProductPopup thinContainer'
		>
			{skuToDelete ? (
				<ConfirmSkuDeletePopup
					sku={skuToDelete}
					visible={deleteSkuConfirmVisible}
					setVisible={setDeleteSkuConfirmVisible}
				/>
			) : null}

			<div className="story error">
				{error}
			</div>

			<div className="story body ProductEditForm">
				<div className="ImageAndSkusContainer">
					<ImageUploadInput
						className="ChangeProductImage"
						onImageUpload={setImageData}
						render={(ref: any) => (
							<>
								<div className="ActionContainer">
									{fullEditMode ? (
										<button className="button neutral plain viewAll" onClick={() => ref.current.click()}>
											Change Picture
										</button>
									) : null}
								</div>
								<img src={imageData?.imageURL || activeProduct?.imageURL || purchaseBladeImage} className="ProductImagePreview" />
							</>
						)}
					/>
				</div>
				<div className="EditForm">
					{fullEditMode ? (
						<>
							<input
								type="text"
								name="name"
								className="input wide"
								value={data.name || ''}
								onChange={changeHandler}
								placeholder="Product Name"
							/>
							<div className="subStory wide" >
								<label>Brand:</label>
								<input
									type="text"
									name="brand"
									className="input wide"
									value={data.brand || ''}
									onChange={changeHandler}
									placeholder="eg. Bauer Tuuk or CCM"
								/>
							</div>
						</>
					) : (
						<>
							{data.name}
							<div className="subStory wide" >
								<label>Brand:</label>
								{data.brand}
							</div>
							<div className="subStory wide" >
								<label>SKU:</label>
								{data.skuCode}
							</div>
						</>
					)}
					<div className="subStory wide" >
						<label>Price:</label>
						<div className="PriceInput">
                            $
							{fullEditMode ? (
								<input
									type="number"
									name='price'
									className="input"
									value={data.price || ''}
									onChange={changeHandler}
									onBlur={(e: any) => {
										setData({
											...data,
											[e.target.name]: (Math.round((parseFloat(e.target.value) + Number.EPSILON) * 100) / 100).toFixed(2)
										})

									}}
								/>

							) : (
								<>
									{data.price}&nbsp;
								</>
							)}

                            CAD
						</div>
					</div>
					<div className="subStory wide" >
						<label>Availability:</label>
						<select
							name='inStock'
							className='input wide'
							value={data.inStock || ''}
							onChange={changeHandler}
							style={{ color: data.inStock == 'Out of Stock' ? 'var(--negativeAccentColor)' : '' }}
						>
							<option value="In Stock">
                                In Stock
							</option>
							<option value="Out of Stock">
                                Out of Stock
							</option>
						</select>
					</div>
				</div>
			</div>

			<div className="story body ProductEditForm">
				<div className="half">
					<div className="AvailableSizes">Available Sizes</div>
					<table className="wide AvailableSizeTable">
						<tbody>
							{activeProduct.skus.map((sku: ProductSku) => (
								<ManageSkuRow
									key={sku.productSkuID}
									sku={sku}
									onDelete={() => {
										setSkuToDelete(sku)
										setDeleteSkuConfirmVisible(true)
									}}
									showDelete={fullEditMode && isOwner}
								/>
							))}
						</tbody>
					</table>
				</div>
				{fullEditMode ? (
					<div className="half AvailableSizes" >
						<label>Add a Size:</label>
						<div className="SizeAddForm">
							<input
								type="text"
								name="size"
								className="input half"
								value={size}
								onChange={(e: any) => setSize(e.target.value)}
								placeholder="###"
							/>
							<FreeButton className="half" onClick={() => onAddSize()}>Add Size</FreeButton>
						</div>
					</div>
				) : null}
			</div>
			<div className="wide SaveButtonContainer">
				<FreeButton
					onClick={() => onSubmit()}
					loading={loading}
				>
				Save Changes
				</FreeButton>
			</div>
		</Popup>
	)
}
