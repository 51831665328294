import React, { useEffect, useState, SetStateAction } from 'react'
import Popup from '../../Components/Popup/Popup'
import './SignInPopup.css'
import { ControlledTabContainer, InfoTab } from 'react-ui-scaffold'
import LogInScreen from './Support/LoginScreen/LogInScreen'
import CreateAccount from './Support/CreateAccount/CreateAccount'
import ForgotPassword from './Support/ForgotPassword/ForgotPassword'

interface SignInPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
	defaultEmail?: string
	defaultMessage?: any
	defaultActiveTab?: string
}

export default function SignInPopup({ visible, setVisible, defaultEmail = '', defaultMessage, defaultActiveTab = 'login' }: SignInPopupProps) {
	
	const [activeTab, setActiveTab] = useState('login')
	
	const [data, setData] = useState({
		email: defaultEmail,
		password: '',
		createEmail: '',
		createPassword: '',
		confirmPassword: '',
		createName: ''
	})
	
	useEffect(() => {
		if (defaultEmail){
			setData({
				...data,
				email: defaultEmail
			})
		}
		
		setMessage(defaultMessage || {})
		
		
	}, [defaultEmail, defaultMessage])
	
	
	//Always show the default tab when you open the popup
	useEffect(() => {
		if (!visible){
			setActiveTab(defaultActiveTab)
			setMessage(defaultMessage || {})
		}
	}, [visible])
	
	const [message, setMessage] = useState(defaultMessage ? defaultMessage : { text: '', color: '' })
	
	const [loading, setLoading] = useState({})
	
	function changeHandler(e: any){
		setData({ ...data, [e.target.name]:e.target.value })
	}
	
	
	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='Welcome to Skatescribe'
			className='SignInPopup'
		>
			
			<div className="story" style={{ color: message.color, textAlign: 'center' }}>
				{message.text}
			</div>
			
			<div className="line"></div>
			
			<ControlledTabContainer activeTab={activeTab}>
				<InfoTab tabID={'login'}>
					<LogInScreen
						changeHandler={changeHandler}
						data={data}
						loading={loading}
						setActiveTab={setActiveTab}
						setMessage={setMessage}
						setVisible={setVisible}
						setLoading={setLoading}
					/>
				</InfoTab>
				
				<InfoTab tabID={'create'}>
					<CreateAccount
						changeHandler={changeHandler}
						data={data}
						setData={setData}
						loading={loading}
						setActiveTab={setActiveTab}
						setMessage={setMessage}
						setLoading={setLoading}
					/>
				</InfoTab>
				
				<InfoTab tabID={'forgot'}>
					<ForgotPassword
						setActiveTab={setActiveTab}
						setMessage={setMessage}
					/>
				</InfoTab>
			</ControlledTabContainer>
			
		</Popup>
	)
}
