import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrder, fetchValidActionsForStatus, Order, statusBadgeColorMap } from '../../../../../Redux/Slices/orderSlice'
import moment from 'moment'
import { useHistory } from 'react-router'
import './OrderCard.css'


interface OrderCardProps {
	order: Order;
}

export default function OrderCard({
	order,
}: OrderCardProps) {
	const history = useHistory()
	const dispatch = useDispatch()
	const activeOrder = useSelector((s: any) => s.order.activeOrder)
	const setActiveOrder = () => {
		const { orderID, status }: { orderID: string, status: string } = order
		dispatch(fetchOrder({ orderID }))
		dispatch(fetchValidActionsForStatus({ status }))
		history.push(`/Orders/${orderID}`)
	}
	
	return (
		<div
			className={`OrderCard ${order.orderID == activeOrder?.orderID && 'Active'}`}
			onClick={setActiveOrder}
		>
			<div className="OrderCardDetails">
				<div>Order #{order.shortID} ({order.itemCount} items)</div>
				<div>{order.placedBy}</div>
				<div>{order.contactFirstName} {order.contactLastName}</div>
			</div>
			<div className="OrderCardDetails StatusDate">
				<div>{moment(order.placedDate).format('MMM D, YYYY')}</div>
				<div style={{ color: statusBadgeColorMap[order.status] }} className="OrderCardDetailsStatus">{order.status}</div>
			</div>
		</div>
	)
}
