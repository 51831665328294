import React from 'react'
import './StatusTab.css'
import { useSelector } from 'react-redux'
import { Loading } from 'react-ui-scaffold'

interface StatusTabProps {
    type: string;
    current: string;
    onSelect: Function;
}
export default function StatusTab({
	type,
	current,
	onSelect,
}: StatusTabProps) {
	const loading = useSelector((s: any) => s.order.loaders[type] || 0)
	const orderCount = useSelector((s: any) => s.order.counts[type] || 0)
	const active = type === current

	return (
		<div
			className={`StatusTab Tab ${active ? 'Active' : ''}`}
			onClick={() => { onSelect(type) }}
		>
			<span>{type} Orders ({orderCount})</span>&nbsp;
			{loading ? <Loading /> : null}
		</div>
	)
}
