import React, { useRef } from 'react'
import './ImageUploadInput.css'

interface ImageUploadInputProps {
	render: Function
    onImageUpload: Function
	className?: string
}

export default function ImageUploadInput({ render, onImageUpload, className }: ImageUploadInputProps) {
	const inputFile = useRef(null)

	const onImageChange = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0]
			onImageUpload({ imageURL: URL.createObjectURL(img), file: event.target.files[0] })
		}
	}

	return (
		<div className={className}>
			<input
				className="ImageUploadInput"
				ref={inputFile}
				type="file"
				onChange={onImageChange}
				accept="image/*"
			/>
			{render(inputFile)}
		</div>
	)
}
