
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ConfirmEmployeeRemove from '../../../../Popups/ConfirmEmployeeRemove/ConfirmEmployeeRemove'
import { Admin, revokeAdminAccess } from '../../../../Redux/Slices/employeeSlice'
import './RemoveEmployeeButton.css'

export default function RemoveEmployeeButton({ employee }: { employee: Admin }) {
	const dispatch = useDispatch()

	const removeEmployee = () => {
		dispatch(revokeAdminAccess({ email: employee.adminEmail }))
	}

	const [confirmActionPopupOpen, setConfirmActionPopupOpen] = useState(false)
	
	return (
		<>
			<ConfirmEmployeeRemove
				visible={confirmActionPopupOpen}
				setVisible={setConfirmActionPopupOpen}
				employee={employee}
				onConfirm={removeEmployee}
			/>
			<div className="RemoveEmployeeButton" onClick={() => setConfirmActionPopupOpen(true)}>
				<div>Remove Employee</div>
			</div>
		</>
	)
}
