import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import DatePicker from '../../../../Components/DatePicker/DatePicker'
import { statusBadgeColorMap } from '../../../../Redux/Slices/orderSlice'
import './AppliedFilters.css'

function DateFilter({ advancedFilters, setAdvancedFilters }: { advancedFilters: any, setAdvancedFilters: Function }) {
	const { dateType, beforeDate, afterDate, fromDate, toDate } = advancedFilters
	if (dateType == 'Before') {
		return (
			<div className="DateFilters">
				Before:
				<DatePicker
					date={beforeDate}
					setDate={(beforeDate: Date) => {
						setAdvancedFilters({ ...advancedFilters, beforeDate })
					}}
				/>
			</div>
		)
	} else if (dateType == 'After') {
		return (
			<div className="DateFilters">
				After:
				<DatePicker
					date={afterDate}
					setDate={(afterDate: Date) => {
						setAdvancedFilters({ ...advancedFilters, afterDate })
					}}
				/>
			</div>
		)
	} else if (dateType == 'Range') {
		return (
			<div className="Range">
				<div className="DateFilters">
					From:
					<DatePicker
						date={fromDate}
						setDate={(fromDate: Date) => {
							setAdvancedFilters({ ...advancedFilters, fromDate })
						}}
					/>
				</div>

				<div className="DateFilters">
					To:
					<DatePicker
						date={toDate}
						setDate={(toDate: Date) => {
							setAdvancedFilters({ ...advancedFilters, toDate })
						}}
					/>
				</div>
			</div>
		)
	}
	return null
}

export default function AppliedFilters({ advancedFilters = {}, setAdvancedFilters, onChangeFilter }: { advancedFilters: any, setAdvancedFilters: Function, onChangeFilter: Function,  }) {
	const {
		statuses = [],
		firstName,
		lastName,
		email,
		phone
	} = advancedFilters
	const filteredOrders = useSelector((s: any) => s.order.orders.Filtered || [])

	const changeHandler = (e: any) => {
		setAdvancedFilters({ ...advancedFilters, [e.target.name]: e.target.value })
	}
	
	return (
		<div className="AppliedFilters">
			<div className="AppliedFiltersTitle">
				Filtered Search ({filteredOrders.length})
				<FontAwesomeIcon
					icon={faTimes}
					color="#5e5e5e"
					className="exitIcon"
					onClick={() => setAdvancedFilters({})}
				/>
			</div>
			{ statuses.length ? (
				<div className="StatusCardContainer">
					{statuses.map((status: string) => {
						return (
							<div
								key={status}
								className="StatusCard"
							>
								<div style={{ color: statusBadgeColorMap[status] }}>{status}</div>
								<FontAwesomeIcon
									icon={faTimes}
									color="#5e5e5e"
									className="exitIcon"
									onClick={() => {
										const newStatuses = [...statuses].filter((element) => element != status)
										setAdvancedFilters({ ...advancedFilters, statuses: newStatuses })
									}}
								/>
							</div>
						)
					})}
				</div>
			) : null}
			<DateFilter
				advancedFilters={advancedFilters}
				setAdvancedFilters={setAdvancedFilters}
			/>
			<form autoComplete='off'>
				<div className="CustomerFilterContainer">
					<div className='HalfFilter'>
						<label>First Name:</label>
						<input
							className="input wide"
							value={firstName}
							placeholder='First Name'
							name="firstName"
							onChange={changeHandler}
						/>
					</div>
					<div className='HalfFilter'>
						<label>Last Name:</label>
						<input
							className="input wide"
							value={lastName}
							placeholder='Last Name'
							name="lastName"
							onChange={changeHandler}
						/>
					</div>
					<div className='HalfFilter'>
						<label>Email:</label>
						<input
							className="input wide"
							value={email}
							placeholder='Email'
							name="email"
							onChange={changeHandler}
						/>
					</div>
					<div className='HalfFilter'>
						<label>Phone Number:</label>
						<input
							className="input wide"
							value={phone}
							placeholder='Phone Number'
							name="phone"
							onChange={changeHandler}
						/>
					</div>
				</div>
			</form>
			<FreeButton
				onClick={() => onChangeFilter()}
				className="ChangeFiltersButton"
			>
				Change Filters
			</FreeButton>
		</div>
	)
}
