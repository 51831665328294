import React from 'react'
import { Order } from '../../../../../Redux/Slices/orderSlice'
import './OrderContent.css'
import ItemCard from './Support/ItemCard'

export default function OrderContent({ order }: { order: Order }) {
	const { contents = [] } = order
	
	return (
		<div className='OrderContent'>
			{contents.map((item: any, index: number) => {
				return (
					<div key={item.itemID} className="ContentItem">
						<ItemCard item={item} index={index} order={order} />
					</div>
				)
			})}
            
		</div>
	)
}
