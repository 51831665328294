import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'


export const fetchAllAdmins = createAsyncThunk('employees/fetchAllAdmin', async () => {
	const fetchRes = await beam.get('/api/admin/fetchAllAdmin').catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
	}
})

export const updateRole = createAsyncThunk('employees/updateRole', async ({ role, adminEmail }: { role: string, adminEmail: string }) => {
	const updateRes = await beam.post('/api/admin/updateRole', { role, adminEmail }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: updateRes.response.data,
	}
})

export const addLocation = createAsyncThunk('employees/addLocation', async ({ locations, email }: { locations: number[], email: string }) => {
	const addRes = await beam.post('/api/admin/addLocationsToAdmin', { locations, email }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: addRes.response.data,
	}
})

export const removeLocation = createAsyncThunk('employees/removeLocation', async ({ locationID, email }: { locationID: number, email: string }) => {
	const removeRes = await beam.post('/api/admin/removeLocationsFromAdmin', { locations: [locationID], email }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: removeRes.response.data,
	}
})

export const revokeAdminAccess = createAsyncThunk('employees/revokeAdminAccess', async ({ email }: { email: string }) => {
	const removeRes = await beam.post('/api/admin/revokeAdminAccess', { email }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: removeRes.response.data,
	}
})

export const inviteAdmin = createAsyncThunk('employees/inviteAdmin', async ({ employee: { adminEmail, role }, callBack }: { employee: Admin, callBack: Function }) => {
	const inviteRes = await beam.post('/api/admin/inviteAdmin', { email: adminEmail, role }).catch(err => {
		callBack('error', err.response.error)
		return Promise.reject(err)
	})
	callBack('success', inviteRes.response.data)
	return {
		data: inviteRes.response.data,
	}
})

export interface Admin {
	adminEmail: string;
	status?: string;
	role: string;
	name?: string;
	addedBy?: string;
	addedDate?: Date;
	phone?: string;
	availableLocations: any[]
}

const ordersSlice = createSlice({
	name: 'employees',
	initialState: {
		employees: [],
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAllAdmins.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Admin[] } = payload
			state.employees = data
		}),
		builder.addCase(updateRole.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Admin } = payload
			const index = state.employees.findIndex((employee: Admin) => employee.adminEmail == data.adminEmail)
			state.employees[index] = { ...state.employees[index], ...data }
		})
		builder.addCase(addLocation.fulfilled, (state: any, { payload, meta: { arg } }: any) => {
			const { email }: { email: string } = arg
			const { data }: { data: any } = payload
			const index = state.employees.findIndex((employee: Admin) => employee.adminEmail == email)
			state.employees[index] = {
				...state.employees[index],
				availableLocations: [
					...state.employees[index].availableLocations,
					...data
				]
			}
		})
		builder.addCase(removeLocation.fulfilled, (state: any, { payload, meta: { arg } }: any) => {
			const { email }: { email: string } = arg
			const { data }: { data: any[] } = payload
			const index = state.employees.findIndex((employee: Admin) => employee.adminEmail == email)
			state.employees[index] = {
				...state.employees[index],
				availableLocations: state.employees[index].availableLocations.filter((location: any) => {
					return !data.find((element: any) => {
						return element.adminLocationID == location.adminLocationID
					})
				})
			}
		})
		builder.addCase(revokeAdminAccess.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Admin } = payload
			const index = state.employees.findIndex((employee: Admin) => employee.adminEmail == data.adminEmail)
			state.employees.splice(index, 1)
		})
		builder.addCase(inviteAdmin.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Admin } = payload
			state.employees.push({ ...data, availableLocations: [] })
		})
	}
})

export default ordersSlice.reducer

