import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import './CustomerCard.css'
import { Customer, fetchCustomer } from '../../../../../Redux/Slices/customerSlice'


interface CustomerCardProps {
	customer: Customer;
}

export default function CustomerCard({
	customer,
}: CustomerCardProps) {
	const history = useHistory()
	const dispatch = useDispatch()
	const activeCustomer = useSelector((s: any) => s.customer.activeCustomer)
	const setActiveOrder = () => {
		const { playerEmail } = customer
		dispatch(fetchCustomer({ playerEmail }))
		history.push(`/Customers/${playerEmail}`)
	}
	
	return (
		<div
			className={`CustomerCard ${customer.playerEmail == activeCustomer?.playerEmail && 'Active'}`}
			onClick={setActiveOrder}
		>
			<div className="OrderCardDetails">
				<div>{customer.playerFirstName} {customer.playerLastName}</div>
				<div>{customer.playerEmail}</div>
				<div>{customer.phone}</div>
			</div>
		</div>
	)
}
