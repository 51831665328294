import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'

export interface ProductSku {
	productSkuID: number
	productID: number
	name: string
	status: string
	inStock: boolean
}

export interface Product {
	productID: number
	name: string
	brand?: string
	type: string
	default: boolean
	skuCode?: string
	price: number
	imageURL?: string
	inStock: boolean
	skus?: ProductSku[]
}

export const fetchActiveProducts = createAsyncThunk('products/fetchActiveProducts', async ({ search }: { search?: string }) => {
	const productRes = await beam.get(`/api/admin/fetchActiveProducts${search ? `?search=${search}`: ''}`)
	return { products: productRes.response.data }
})

export const fetchDeletedProducts = createAsyncThunk('products/fetchArchivedProducts', async () => {
	const productRes = await beam.get('/api/admin/fetchArchivedProducts')
	return { products: productRes.response.data }
})

export const createProduct = createAsyncThunk('products/createProduct', async ({ data, callback }: { data: any, callback?: Function }) => {
	const productCreateRes = await beam.post('/api/admin/createProduct', data, {}).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to create product!')
	})
	callback && callback()
	return { data: productCreateRes.response.data }
})

export const updateProduct = createAsyncThunk('products/updateProduct', async ({ data, callback }: { data: any, callback?: Function }) => {
	const productUpdateRes = await beam.post('/api/admin/updateProduct', data, {}).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to update product!')
	})
	callback && callback()
	return { data: productUpdateRes.response.data }
})

export const updateProductStock = createAsyncThunk('products/updateProductStock', async ({ data, callback }: { data: any, callback?: Function }) => {
	const productUpdateRes = await beam.post('/api/admin/updateProductStock', data).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to update product stock!')
	})
	callback && callback()
	return { data: productUpdateRes.response.data }
})

export const deleteProduct = createAsyncThunk('products/deleteProduct', async ({ productID, callback }: { productID: any, callback?: Function }) => {
	const productDeleteRes = await beam.post('/api/admin/archiveProduct', { productID }).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to archive product!')
	})
	callback && callback()
	return { data: productDeleteRes.response.data }
})

export const removeProductFromArchive = createAsyncThunk('products/removeProductFromArchive', async ({ productID }: { productID: any, }) => {
	const productDeleteRes = await beam.post('/api/admin/removeProductFromArchive', { productID }).catch(() => {
		return Promise.reject('Unable to remove product sku from archive!')
	})
	return { data: productDeleteRes.response.data }
})

export const createProductSku = createAsyncThunk('products/createProductSku', async ({ productID, name, callback }: { productID: any, name: string, callback?: Function }) => {
	const productSkuRes = await beam.post('/api/admin/createProductSku', { productID, name }).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to create product!')
	})
	callback && callback()
	return { data: productSkuRes.response.data }
})

export const archiveProductSku = createAsyncThunk('products/archiveProductSku', async ({ productSkuID, callback }: { productSkuID: any, callback?: Function }) => {
	const productDeleteRes = await beam.post('/api/admin/archiveProductSku', { productSkuID }).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to archive product sku!')
	})
	callback && callback()
	return { data: productDeleteRes.response.data }
})

export const updateProductSku = createAsyncThunk('products/updateProductSku', async ({ data }: { data: any }) => {
	const productSkuUpdateRes = await beam.post('/api/admin/updateProductSku', data).catch(() => {
		return Promise.reject('Unable to update product sku!')
	})
	return { data: productSkuUpdateRes.response.data }
})

const updateProductFulfilled = (state: any, { payload }: any) => {
	const { data }: { data: Product } = payload
	state.activeProduct = { ...state.activeProduct, ...data }
	const activeIndex = state.list.findIndex((product: Product) => product.productID == data.productID)
	if (activeIndex != -1) {
		const newList = [...state.list]
		newList[activeIndex] = { ...newList[activeIndex], ...data }
		state.list = newList
	}
	const deletedIndex = state.deletedList.findIndex((product: Product) => product.productID == data.productID)
	if (deletedIndex != -1) {
		const newDeleted = [...state.deletedList]
		newDeleted[deletedIndex] = { ...newDeleted[deletedIndex], ...data }
		state.deletedList = newDeleted
	}
}

const productList: Product[] = []
const deletedProductList: Product[] = []
const productsSlice = createSlice({
	name: 'products',
	initialState: {
		list: productList,
		deletedList: deletedProductList,
		activeProduct: null
	},
	reducers: {
		setActiveOrder: (state: any, action: any) => {
			state.activeProduct = action.payload
		},
		clearActiveOrder: (state: any) => {
			state.activeProduct = null
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchActiveProducts.fulfilled, (state: any, { payload }: any) => {
			const { products = [] }: { products: Product[] } = payload
			state.list = products
		})
		builder.addCase(fetchDeletedProducts.fulfilled, (state: any, { payload }: any) => {
			const { products = [] }: { products: Product[] } = payload
			state.deletedList = products
		})
		builder.addCase(createProduct.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Product } = payload
			state.list = [...state.list, { ...data, skus: [] }]
		})
		builder.addCase(updateProduct.fulfilled, updateProductFulfilled)
		builder.addCase(updateProductStock.fulfilled, updateProductFulfilled)
		builder.addCase(deleteProduct.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Product } = payload
			const activeIndex = state.list.findIndex((product: Product) => product.productID == data.productID)
			if (activeIndex != -1) {
				const newList = [...state.list]
				const newDeleted = [...state.deletedList]
				newDeleted.push({ ...newList[activeIndex], ...data })
				newList.splice(activeIndex, 1)
				state.list = newList
				state.deletedList = newDeleted
			}
		})
		builder.addCase(removeProductFromArchive.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Product } = payload
			const archiveIndex = state.deletedList.findIndex((product: Product) => product.productID == data.productID)
			if (archiveIndex != -1) {
				const newList = [...state.list]
				const newDeleted = [...state.deletedList]
				newList.push({ ...newDeleted[archiveIndex], ...data })
				newDeleted.splice(archiveIndex, 1)
				state.list = newList
				state.deletedList = newDeleted
			}
		})
		builder.addCase(createProductSku.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: ProductSku } = payload
			const { productID } = data
			state.activeProduct.skus.push(data)

			const activeIndex = state.list.findIndex((product: Product) => product.productID == productID)
			if (activeIndex != -1) {
				const product = state.list[activeIndex]
				product.skus.push(data)
			}
			const deletedIndex = state.deletedList.findIndex((product: Product) => product.productID == productID)
			if (deletedIndex != -1) {
				const product = state.deletedList[deletedIndex]
				product.skus.push(data)
			}
		})
		builder.addCase(archiveProductSku.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: ProductSku } = payload
			const { productID, productSkuID } = data
			const findAndRemoveSku = (product: Product) => {
				if (product.skus) {
					const skuIndex = product.skus.findIndex((sku: ProductSku) => sku.productSkuID == productSkuID)
					if (skuIndex != -1) {
						product.skus.splice(skuIndex, 1)
					}
				}
			}
			state.activeProduct = { ...state.activeProduct }
			findAndRemoveSku(state.activeProduct)

			const activeIndex = state.list.findIndex((product: Product) => product.productID == productID)
			if (activeIndex != -1) {
				findAndRemoveSku(state.list[activeIndex])
			}
			const deletedIndex = state.deletedList.findIndex((product: Product) => product.productID == productID)
			if (deletedIndex != -1) {
				findAndRemoveSku(state.deletedList[deletedIndex])
			}
		})
		builder.addCase(updateProductSku.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: ProductSku } = payload
			const { productID, productSkuID, inStock } = data
			const findAndUpdateSku = (product: Product) => {
				if (product.skus) {
					const skuIndex = product.skus.findIndex((sku: ProductSku) => sku.productSkuID == productSkuID)
					if (skuIndex != -1) {
						product.skus[skuIndex] = { ...product.skus[skuIndex], inStock }
					}
				}
			}
			state.activeProduct = { ...state.activeProduct }
			findAndUpdateSku(state.activeProduct)

			const activeIndex = state.list.findIndex((product: Product) => product.productID == productID)
			if (activeIndex != -1) {
				findAndUpdateSku(state.list[activeIndex])
			}
			const deletedIndex = state.deletedList.findIndex((product: Product) => product.productID == productID)
			if (deletedIndex != -1) {
				findAndUpdateSku(state.deletedList[deletedIndex])
			}
		})
	}
})

export const { setActiveOrder, clearActiveOrder } = productsSlice.actions
export default productsSlice.reducer
