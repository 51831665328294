import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'


export const fetchAllBasicPresets = createAsyncThunk('presets/fetchAllBasicPresets', async () => {
	const fetchRes = await beam.get('/api/fetchAllBasicPresets').catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
	}
})

export const updatePreset = createAsyncThunk('presets/updatePreset', async ({ basicPresetID, name, radiusValue, pitchValue, hollowValue, callback }: { basicPresetID: number, name: string, radiusValue: number, pitchValue: number, hollowValue: string, callback?: Function }) => {
	const updateRes = await beam.post('/api/admin/updateBasicPreset', { basicPresetID, name, radiusValue, pitchValue, hollowValue }).catch(err => {
		return Promise.reject(err)
	})

	callback && callback()

	return {
		data: updateRes.response.data,
	}
})

export interface BasicPreset {
    basicPresetID: number
    name: string
    hollowValue: string
    radiusValue: number
    pitchValue: number
}

const basicPresetSlices = createSlice({
	name: 'basicPresets',
	initialState: {
		presets: [],
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAllBasicPresets.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: BasicPreset[] } = payload
			state.presets = data
		}),
		builder.addCase(updatePreset.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: BasicPreset } = payload
			const index = state.presets.findIndex((preset: BasicPreset) => preset.basicPresetID == data.basicPresetID)
			state.presets[index] = { ...state.presets[index], ...data }
		})
	}
})

export default basicPresetSlices.reducer

