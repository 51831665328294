import React from 'react'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import LabelText from '../../../../../Components/LabelText/LabelText'
import { Customer } from '../../../../../Redux/Slices/customerSlice'
import './CustomerInfo.css'

export default function CustomerInfo({ customer }: { customer: Customer }) {
	return (
		<div className='CustomerInfo'>
			<CollapsibleCard
				expandedContent={
					<>
						<LabelText
							label="Email"
							text={customer.playerEmail}
						/>
						<LabelText
							label="Phone"
							text={customer.phone}
						/>
						<LabelText
							label="User Name"
							text={`${customer.playerFirstName || ''} ${customer.playerLastName || ''}`}
						/>
					</>
				}
			>
                Customer Info
			</CollapsibleCard>
		</div>
	)
}
