import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { SetStateAction, useEffect } from 'react'
import './Popup.css'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { openPopup, closePopup } from '../../Redux/Slices/viewSlice'

interface PopupProps {
	children: React.ReactNode;
	style?: React.StyleHTMLAttributes<HTMLDivElement>;
	z?: number;
	visible?: boolean;
	setVisible: React.Dispatch<SetStateAction<boolean>>;
	title: string;
	className?: string;
	onExit?: Function;
	action?: React.ReactNode;
}

export default function Popup({
	children,
	style,
	z = 1,
	visible,
	setVisible,
	title,
	className,
	onExit,
	action,
}: PopupProps) {

	const dispatch = useDispatch()

	useEffect(() => {

		if (visible) {
			dispatch(openPopup())
		} else {
			dispatch(closePopup())
		}

		const handleEsc = (ev: KeyboardEvent) => {
			if (ev.key === 'Escape') {
				setVisible(false)
			}
		}
		window.addEventListener('keydown', handleEsc)
		return () => window.removeEventListener('keydown', handleEsc)
	}, [visible])

	if (!visible) {
		return null
	}

	function internalClickHandler(e: React.MouseEvent<HTMLDivElement>) {
		e.stopPropagation()
	}

	function exit(e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) {
		e.stopPropagation()
		if (onExit) {
			onExit({ setVisible })
		} else {
			setVisible(false)
		}
	}

	return createPortal(
		<div className={'popupBG flexCenter '} onMouseDown={exit} style={{ zIndex: z + 500 }}>
			<div className={'Popup ' + className} style={{ ...style }} onMouseDown={internalClickHandler}>
				<div className="titleBar">
					{action ? action : <div className="spacer"></div>}

					<h1 className='hemi'>
						{title}
					</h1>

					<button className="button plain" onClick={exit}>
						<FontAwesomeIcon icon={faTimes} className="exitIcon" />
					</button>
				</div>

				<div className="content">
					{children}
				</div>

			</div>
		</div>
		, document.getElementById('portalDestination-popupContainer') as Element)
}
