import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'

export default function CreditCardInfo({ cardDetails }: { cardDetails: any }) {
	let icon: any
	if (cardDetails.card_type == 'VI') {
		icon = faCcVisa
	} else if (cardDetails.card_type == 'MC') {
		icon = faCcMastercard
	}
	const lastFour = useMemo(() => {
		if (cardDetails?.last_four) {
			return cardDetails?.last_four
		}
		return cardDetails.number.substr(cardDetails.number.length - 4)
	}, [cardDetails])
	return (
		<>
			<div className="flexY">
				{icon && <FontAwesomeIcon size="2x" icon={icon} style={{ paddingRight: 10 }}/>}
				ending in *{lastFour}
			</div>
		</>
	)
}
