import React from 'react'
import { useState } from 'react'
import auth from '../../../../Handlers/auth'
import { FreeButton } from 'react-ui-scaffold'

interface ForgotPasswordProps {
	setActiveTab: Function
	setMessage: Function
}

export default function ForgotPassword({ setActiveTab, setMessage }: ForgotPasswordProps) {
	
	const [data, setData] = useState({
		email: ''
	})
	
	function changeHandler(e: any){
		setData({
			...data,
			[e.target.name]: e.target.value
		})
	}
	
	function submit(){
		auth.resetPassword(data.email).then(res => {
			if (res.error){
				setMessage({
					text: res.error,
					color: 'var(--negativeAccentColor)'
				})
				return
			}
			setMessage({
				color: 'var(--positiveAccentColor)',
				text: 'Email sent to ' + data.email
			})
			setData({ ...data, email: '' })
		}).catch((err) => {
			console.log(err)
			setMessage({
				text: 'Something went wrong!',
				color: 'var(--negativeAccentColor)'
				
			})
		})
	}
	
	return (
		<div className='ForgotPassword'>
			<div className="story">
				<label htmlFor="Email">Enter your email</label>
				<input
					type="text"
					className="input wide"
					placeholder='example@email.com'
					data-htmlFor='email'
					name='email'
					value={data.email}
					onChange={changeHandler}
				/>
			</div>


			<div className="story">
				<FreeButton className="button wide" onClick={submit}>
					Submit
				</FreeButton>
				<div className="story">
					<FreeButton className="button plain wide neutral" onClick={() => setActiveTab('login')}>
						<b>Back to log in</b>
					</FreeButton>
				</div>
			</div>
		</div>
	)
}
