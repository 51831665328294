import React from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Admin } from '../../../../Redux/Slices/employeeSlice'
import './LocationsSelection.css'

export default function LocationsSelection({ employee, onLocationChange }: { employee: Admin, onLocationChange: Function }) {
	const locations: any[] = useSelector((s: any) => s.activeUser.data?.availableLocations || [])
	const activeLocationMap = useMemo(() => {
		const activeLocationMap: any = {}
		employee.availableLocations.map((location: any) => {
			activeLocationMap[location.locationID] = location.adminLocationID
		})
		return activeLocationMap
	}, [employee])

	const onCheckToggle = (locationID: number) => {
		onLocationChange(locationID, !activeLocationMap[locationID], employee)
	}

	if (employee.role == 'owner') {
		return null
	}
	return (
		<div className="LocationsSelection">
			<div className="bold">Stores:</div>
			{locations.map(location => (
				<div className="row Location" key={location.locationID}>
					<div>{location.title}</div>
					<input
						type="checkbox"
						className="LocationsSelectionCheckbox"
						checked={Boolean(activeLocationMap[location.locationID])}
						onChange={() => onCheckToggle(location.locationID)}
					/>
				</div>
			))}
		</div>
	)
}
