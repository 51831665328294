import React from 'react'
import { useDispatch } from 'react-redux'
import { ProductSku, updateProductSku } from '../../../../../Redux/Slices/productsSlice'
import './ManageSkuRow.css'

export default function ManageSkuRow({ sku, onDelete, showDelete }: { sku: ProductSku, onDelete: Function, showDelete: boolean }) {
	const dispatch = useDispatch()
	const onInStockChange = (e: any) => {
		dispatch(updateProductSku({
			data: {
				inStock: e.target.value == 'In Stock',
				productSkuID: sku.productSkuID
			}
		}))
	}

	return (

		<tr className="ManageSkuRow">
			<td>{sku.name}</td>
			<td>
				<select
					name='inStock'
					className='input wide'
					value={sku.inStock ? 'In Stock' : 'Out of Stock'}
					onChange={onInStockChange}
					style={{ color: !sku.inStock ? 'var(--negativeAccentColor)' : '' }}
				>
					<option value="In Stock">
                        In Stock
					</option>
					<option value="Out of Stock">
                        Out of Stock
					</option>
				</select>
			</td>
			{showDelete ? (
				<td>
					<button className="button neutral plain DeleteButton" onClick={() => onDelete()}>Delete</button>
				</td>
			) : null}
		</tr>
	)

}
