import React from 'react'
import { useSelector } from 'react-redux'

interface InternalContainerProps {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

interface SpaceMap {
    mobile: number;
    tablet: number;
    desktop: number;
}

export const space: SpaceMap = {
	mobile: 40,
	tablet: 100,
	desktop: 250,
}

export default function InternalContainer({ children, className, style }: InternalContainerProps) {

	const screenSize = useSelector((s: any) => s.view.screenSize)


	return (
		<div className={'InternalContainer ' + className} style={{
			width: `calc(100vw - ${space[screenSize as keyof typeof space]}px) `,
			...style
		}}>
			{children}
		</div>
	)
}
