import React, { useState, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../Components/Popup/Popup'
import { archiveProductSku, ProductSku } from '../../../Redux/Slices/productsSlice'
import './ConfirmSkuDeletePopup.css'

interface ConfirmSkuDeletePopupProps {
	sku: ProductSku
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
}

export default function ConfirmSkuDeletePopup({ sku, visible, setVisible }: ConfirmSkuDeletePopupProps) {
	const dispatch = useDispatch()
	const activeProduct = useSelector((s: any) => s.product.activeProduct)

	const [error, setError] = useState(null)
	const [view, setView] = useState('')

	useEffect(() => {
		if (visible) {
			setError(null)
			setView('delete confirm')
		}
	}, [visible])

	const onDelete = () => {
		dispatch(archiveProductSku({
			productSkuID: sku.productSkuID,
			callback: (err: any) => {
				if (err) {
					return setError(err)
				}
				setView('confirmed')
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={view == 'delete confirm' ? 'Delete Item Size?' : 'Item Size Deleted'}
			className='ConfirmSkuDeletePopup thinContainer'
		>
			<div className="story error">
				{error}
			</div>

			<div className="story body">
				{view == 'delete confirm' ? (
					<>
						<div>
                            Are you sure you want to delete the size&nbsp;
							<span className="BoldName">{sku.name}</span>&nbsp;
							from <span className="BoldName">{activeProduct.name}</span>?
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={() => onDelete()}>Yes, Delete Size</FreeButton>
							<FreeButton onClick={() => setVisible(false)}>Cancel</FreeButton>
						</div>
					</>
				) : (
					<>
						<div>
							The size <span className="BoldName">{sku.name}</span>&nbsp;
                            has been deleted.
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={() => setVisible(false)}>Back</FreeButton>
						</div>
					</>
				)}
			</div>

		</Popup>
	)
}
