import './Main.css'
import { Route, Switch } from 'react-router-dom'
import React from 'react'
import Login from '../Login/Login'
import Navbar from '../../Components/Navbar/Navbar'
import SelectLocation from '../SelectLocation/SelectLocation'
import Orders from '../Orders/Orders'
import Employees from '../Employees/Employees'
import Products from '../Products/Products'
import Customers from '../Customers/Customers'


export default function Main() {

	return (
		<div className={'Main'}>
			<Navbar />
			
			<Switch>
				<Route path='/Login' component={Login} />
				<Route path='/Orders/:id' component={Orders} />
				<Route path='/Orders' component={Orders} />
				<Route path='/Employees' component={Employees} />
				<Route path='/Products' component={Products} />
				<Route path='/Customers/:playerEmail' component={Customers} />
				<Route path='/Customers' component={Customers} />
				<Route path='/' component={SelectLocation} />
			</Switch>

		</div>
	)
}
