import React from 'react'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactDatePicker from 'react-datepicker'
import './DatePicker.css'

export default function DatePicker({ date, setDate }:{ date: Date, setDate: Function}) {
	return (
		<div className="DatePicker">
			<ReactDatePicker
				selected={date}
				onChange={(date) => setDate(date)}
				customInput={<input className="DatePicker input" value={date?.toLocaleDateString('en-US')} readOnly />}
			/>

			<FontAwesomeIcon icon={faCalendar} color="#5e5e5e" className="CalendarIcon" />
		</div>
	)
}
