import React from 'react'
import './CustomerDetails.css'
import { useSelector } from 'react-redux'
import CustomerInfo from '../CustomerDetailsSupport/CustomerInfo/CustomerInfo'
import SavedCards from '../CustomerDetailsSupport/SavedCards/SavedCards'
import CustomerOrders from '../CustomerDetailsSupport/CustomerOrders/CustomerOrders'
import { Customer } from '../../../../Redux/Slices/customerSlice'

export default function CustomerDetails() {
	const customer: Customer = useSelector((s: any) => s.customer.activeCustomer)

	if (!customer) {
		return null
	}
	
	return (
		<div
			className="CustomerDetails"
		>
			<div className="CustomerName">
				{customer.playerFirstName} {customer.playerLastName}
			</div>
			<div className="BodyContainer">
				<div className="Body" style={{ paddingRight: 30 }}>
					<CustomerInfo customer={customer} />
					<SavedCards customer={customer} />
				</div>
				<div className="Body">
					<CustomerOrders customer={customer} />
				</div>
			</div>
		</div>
	)
}
