import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BasicPreset, updatePreset } from '../../../../Redux/Slices/basicPresetSlices'
import agilityPresetImage from '../../../../Assets/graphics/agility-preset.svg'
import balancedPresetImage from '../../../../Assets/graphics/balanced-preset.svg'
import speedPresetImage from '../../../../Assets/graphics/speed-preset.svg'
import './PresetCard.css'

interface PresetCardProps {
    preset: BasicPreset
}

const presetImageMap: any = {
	Agility: agilityPresetImage,
	Balanced: balancedPresetImage,
	Speed: speedPresetImage
}

const hollowOptions = ['7/8', '13/16', '3/4', '11/16', '5/8', '9/16', '1/2', '7/16', '3/8', '1']

export default function PresetCard({ preset }: PresetCardProps) {
	const dispatch = useDispatch()

	const { role } = useSelector((s: any) => s.activeUser.data)
	const isOwner = role =='owner'

	const [isEditMode, setIsEditMode] = useState(false)
	const [hollowValue, setHollowValue] = useState('')
	const [radiusValue, setRadiusValue] = useState(0)
	const [pitchValue, setPitchValue] = useState(0)

	const onActionButtonClick = () => {
		if (isEditMode) {
			//sdas
			dispatch(updatePreset({
				basicPresetID: preset.basicPresetID,
				name: preset.name,
				hollowValue,
				radiusValue,
				pitchValue,
				callback: () => {
					setIsEditMode(false)
				}
			}))
		} else {
			setIsEditMode(true)
			setHollowValue(preset.hollowValue)
			setRadiusValue(preset.radiusValue)
			setPitchValue(preset.pitchValue)
		}
	}

	return (
		<div className="PresetCard">
			<div className="ImageSpecsContainer">
				<img
					className="PresetImage"
					src={presetImageMap[preset.name]}
					alt={preset.name}
					draggable='false'
				/>
				<div className="Preset">
					<div className="PresetName">{preset.name} Preset</div>
					<div className="SpecsContainer">
						<div className="SpecContainer">
							<div className="SpecName">Hollow:</div>
							<div className="SpecValue">
								{isEditMode ? (
									<select
										className='input SpecInput'
										value={hollowValue}
										onChange={(e: any) => {
											setHollowValue(e.target.value)
										}}
									>
										{hollowOptions.map((hollowOption: string) => (
											<option
												value={hollowOption}
												key={hollowOption}
											>
												{hollowOption}
											</option>
										))}
									</select>
								) : (
									<div>{preset.hollowValue}</div>
								)}&quot;
							</div>
						</div>
						<div className="SpecContainer">
							<div className="SpecName">Radius:</div>
							<div className="SpecValue">
								{isEditMode ? (
									<input
										className="input SpecInput"
										value={radiusValue}
										onChange={(e: any) => {
											setRadiusValue(e.target.value)
										}}
										type="number"
									/>
								) : (
									<div>{preset.radiusValue}</div>
								)}&lsquo;
							</div>
						</div>
						<div className="SpecContainer">
							<div className="SpecName">Pitch:</div>
							<div className="SpecValue">
                                P{isEditMode ? (
									<input
										className="input SpecInput"
										value={pitchValue}
										onChange={(e: any) => {
											setPitchValue(e.target.value)
										}}
										type="number"
									/>
								) : (
									<div>{preset.pitchValue}</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{ isOwner ? (
				<button className="button neutral plain ActionButton" onClick={onActionButtonClick}>
					{isEditMode ? 'Save Changes' : 'Edit Preset'}
				</button>
			) : null}
		</div>
	)
}
