import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addLocation, Admin, fetchAllAdmins, removeLocation, updateRole } from '../../Redux/Slices/employeeSlice'
import AccessLevelDropDown from './Support/AccessLevelDropDown/AccessLevelDropDown'
import LocationsSelection from './Support/LocationsSelection/LocationsSelection'
import MainContainer from '../../Components/MainContainer/MainContainer'
import './Employees.css'
import RemoveEmployeeButton from './Support/RemoveEmployeeButton/RemoveEmployeeButton'
import EmployeeAdd from '../../Popups/EmployeeAdd/EmployeeAdd'

export default function Employees() {
	const dispatch = useDispatch()
	const history = useHistory()

	const { role, adminEmail } = useSelector((s: any) => s.activeUser.data)
	const { employees = [] }: { employees: Admin[] } = useSelector((s: any) => s.employee)

	const [employeeAddVisible, setEmployeeAddVisible] = useState(false)

	useEffect(() => {
		dispatch(fetchAllAdmins())
	}, [])

	if (role != 'owner') {
		history.push('/')
	}

	const onAccessLevelChange = (role: string, employee: Admin) => {
		dispatch(updateRole({ role, adminEmail: employee.adminEmail }))
	}

	const onCheckToggle = (locationID: number, newState: Boolean, employee: Admin) => {
		if (newState) {
			dispatch(addLocation({ locations: [locationID], email: employee.adminEmail }))
		} else {
			dispatch(removeLocation({ locationID, email: employee.adminEmail }))
		}
	}
	return (
		<div className="Employees">
			<EmployeeAdd
				visible={employeeAddVisible}
				setVisible={setEmployeeAddVisible}
			/>
			<MainContainer style={{ maxWidth: '500px' }}>
				<div className="row header">
					<h1 className='hemi title'>
						Employees
					</h1>
					<button className="button neutral" onClick={() => setEmployeeAddVisible(true)}>
						+Invite Employee
					</button>
				</div>
				<div className="EmployeeContaier">
					{employees.map((employee: Admin) => {
						if (adminEmail == employee.adminEmail) {
							return null
						}
						return (
							<div className="EmployeeCard" key={employee.adminEmail}>
								<h2 className="EmployeeName">{employee.name}</h2>
								<div className="row">
									<div>{employee.adminEmail}</div>
									<AccessLevelDropDown employee={employee} onAccessLevelChange={onAccessLevelChange}/>
								</div>
								<div className="row">
									<div>{employee.phone}</div>
									<LocationsSelection employee={employee} onLocationChange={onCheckToggle} />
								</div>
								<RemoveEmployeeButton employee={employee} />
							</div>
						)
					})}
				</div>
			</MainContainer>
		</div>
	)
}
