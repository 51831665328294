import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import { commentOnOrder, Order } from '../../../../../Redux/Slices/orderSlice'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import './Comments.css'

export default function Comments({ order }: { order: Order }) {
	const dispatch = useDispatch()
	const { comments = [] } = order
	const [comment, setComment] = useState('')

	const onCommentSubmit = () => {
		if (!comment) {
			return
		}
		dispatch(commentOnOrder({
			orderID: order.orderID,
			comment,
		}))
		setComment('')
	}
	return (
		<div className='Comments'>
			<CollapsibleCard
				expandedContent={
					<div className="CommentCardContainer">
						{comments.map((log: any) => {
							return (
								<div
									key={log.orderCommentID}
									className="CommentCard"
								>
									<div>{log.name}</div>
									<div className="Comment">&quot;{log.comment}&quot;</div>
									<div className="CommentDate">{moment(log.commentDate).format('MMM Do, YYYY - hh:mm A')}</div>
								</div>
							)
						})}
						<textarea
							className="input AddCommentInput"
							placeholder="Add a Comment"
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>
						<FreeButton
							onClick={onCommentSubmit}
							className="AddCommentButton"
							disabled={!comment}
						>
							Add Comment
						</FreeButton>
					</div>
				}
				defaultExpanded
			>
				{`Comments (${comments.length})`}
			</CollapsibleCard>
		</div>
	)
}
