import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'
import { Order } from './orderSlice'

export const pageSize = 50

export const fetchCustomers = createAsyncThunk('customers/fetch', async ({ search, page }: { search?: string, page: number }) => {
	const fetchRes = await beam.get(`/api/admin/fetchCustomers?page=${page}${search ? `&search=${search}` : ''}`).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
	}
})

export const fetchCustomer = createAsyncThunk('customer/fetch', async ({ playerEmail }: { playerEmail: string }) => {
	const fetchRes = await beam.post('/api/admin/fetchCustomer', { playerEmail }).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
	}
})

export const fetchCustomerCount = createAsyncThunk('customers/fetchCount', async ({ search }: { search?: string }) => {
	const fetchRes = await beam.get(`/api/admin/fetchCustomerCount?${search ? `&search=${search}` : ''}`).catch(err => {
		return Promise.reject(err)
	})

	return {
		data: fetchRes.response.data,
	}
})

export interface Customer {
    playerEmail: string;
    phone: string;
	playerFirstName: string;
	playerLastName: string;
	postalCode: string;
    cards?: any;
    orders?: Order[];
}

const customerSlice = createSlice({
	name: 'customers',
	initialState: {
		customers: [],
		count: 0,
		loading: true,
		activeCustomer: null,
	},
	reducers: {
		clearActiveCustomer: (state: any) => {
			state.activeOrder = null
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchCustomers.pending, (state: any) => {
			state.loading = true
		})
		builder.addCase(fetchCustomers.rejected, (state: any) => {
			state.loading = false
		})
		builder.addCase(fetchCustomers.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: number } = payload
			state.loading = false
			state.customers = data
		})
		builder.addCase(fetchCustomer.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: any } = payload
			state.activeCustomer = data
		})
		builder.addCase(fetchCustomerCount.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: string } = payload
			state.count = parseInt(data)
		})
	}
})

export const { clearActiveCustomer } = customerSlice.actions
export default customerSlice.reducer

