import React, { useEffect, useState, SetStateAction } from 'react'
import Popup from '../../Components/Popup/Popup'
import './ConfirmRefundPopup.css'
import { FreeButton } from 'react-ui-scaffold'
import { useDispatch } from 'react-redux'
import { Order, refundOrder } from '../../Redux/Slices/orderSlice'

interface ConfirmRefundPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
	order: Order
}

export default function ConfirmRefundPopup({ visible, setVisible, order }: ConfirmRefundPopupProps) {
	const dispatch = useDispatch()

	const [view, setView] = useState('refund confirmation')
	const [error, setError] = useState('')
	
	const onRefundConfirm = () => {
		setError('')
		dispatch(refundOrder({
			orderID: order.orderID,
			callback: (err?: string) => {
				if (err) {
					return setError(err)
				}
				setView('confirmation')
			}
		}))
	}

	const { contactFirstName, contactLastName, finalTotal } = order

	const orderTotal = (finalTotal / 100).toFixed(2)
	
	useEffect(() => {
		setView('refund confirmation')
	}, [visible])
	
	
	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={view == 'refund confirmation' ? 'Refund Order?' : 'Order Refunded'}
			className='ConfirmRefundPopup'
		>
			<div className="story error">
				{error}
			</div>
			
			<div className="story body">
				{view == 'refund confirmation' ? (
					<>
						<div>
							Are you sure you want to refund <span className="RefundAmount">${orderTotal}</span> to {contactFirstName} {contactLastName}?
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={onRefundConfirm}>Yes, Refund Order</FreeButton>
							<FreeButton onClick={() => setVisible(false)}>Cancel</FreeButton>
						</div>
					</>
				) : (
					<>
						<div>
							<span className="RefundAmount">${orderTotal}</span> has been refunded to {contactFirstName} {contactLastName}
						</div>
						<div className="action">
							<FreeButton onClick={() => setVisible(false)}>Back</FreeButton>
						</div>
					</>
				)}
			</div>
			
		</Popup>
	)
}
